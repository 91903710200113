<template>
    <header class="header-container">
        <div class="container">
            <div class="grid header-grid m-0">
                <div class="header-left">
                    <router-link to="/">
                        <div class="logo">
                            <img class="site-logo-spaceDesign" src="../../assets/images/logo/logo.png">
                        </div>
                    </router-link>
                    <!-- <div class="header-left-menu"></div> -->
                </div>
                <div class="header-right header-right-desktop">
                    <ul class="header-left-menuUl header-left-menuUlFirst">
                        <li class="header-left-menuItems header-left-menuItemsServices">
                            <p> Products <i class="pi pi-angle-down" style="font-size: 0.8rem;padding-left: 0.1rem;"></i> </p>

                            <div class="mega-box product-mega-box">
                                <h4 class="mega-menu-title">Products</h4>

                                <div class="content">
                                    <div class="col-12 md:col-3 lg:col-3 product-item item-motor">
                                        <router-link to="/motor" class="pointer-events-none">
                                            <div class="mega-menu-list">                                                
                                                <div class="mega-menu-list-info">
                                                    <h4 class="mega-menu-title">
                                                        Motor <small>Comming soon</small> <br> Insurance
                                                    </h4>
                                                </div>

                                                <div class="mega-menu-icon">

                                                </div>
                                            </div>
                                        </router-link>
                                    </div>

                                    <div class="col-12 md:col-3 lg:col-3 product-item item-travel">
                                        <router-link to="/travel">
                                            <div class="mega-menu-list">
                                                <div class="mega-menu-list-info">
                                                    <h4 class="mega-menu-title">
                                                        Travel <br> Insurance
                                                    </h4>
                                                </div>

                                                <div class="mega-menu-icon">

                                                </div>
                                            </div>
                                        </router-link>
                                    </div>

                                    <div class="col-12 md:col-3 lg:col-3 product-item item-medical">
                                        <router-link to="/medical" class="pointer-events-none">
                                            <div class="mega-menu-list">
                                                <div class="mega-menu-list-info">
                                                    <h4 class="mega-menu-title">
                                                        Medical <small>Comming soon</small> <br> Insurance
                                                    </h4>
                                                </div>

                                                <div class="mega-menu-icon">

                                                </div>
                                            </div>
                                        </router-link>
                                    </div>

                                    <div class="col-12 md:col-3 lg:col-3 product-item item-home">
                                        <router-link to="/" class="pointer-events-none">
                                            <div class="mega-menu-list">
                                                <div class="mega-menu-list-info">
                                                    <h4 class="mega-menu-title">
                                                        Home <small>Comming soon</small> <br> Insurance
                                                    </h4>
                                                </div>

                                                <div class="mega-menu-icon">

                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="header-left-menuItems header-left-menuItemsServices menuItemsCompany">
                            <p> Company <i class="pi pi-angle-down" style="font-size: 0.8rem;padding-left: 0.1rem;"></i>
                            </p>

                            <div class="mega-box">
                                <div class="content">
                                    <div class="grid w-full">
                                        <div class="col-12 md:col-12 lg:col-12">
                                            <router-link to="/">
                                                <div class="mega-menu-list">
                                                    <div class="mega-menu-list-info">
                                                        <h4 class="mega-menu-title">About Us</h4>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>

                                        <div class="col-12 md:col-12 lg:col-12">
                                            <router-link to="/">
                                                <div class="mega-menu-list">
                                                    <div class="mega-menu-list-info">
                                                        <h4 class="mega-menu-title">Legal</h4>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>

                                        <div class="col-12 md:col-12 lg:col-12">
                                            <router-link to="/privacy-policy">
                                                <div class="mega-menu-list border-0">
                                                    <div class="mega-menu-list-info">
                                                        <h4 class="mega-menu-title">Privacy Policy</h4>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="header-left-menuItems header-left-menuItemsServices menuItemsCompany">
                            <p> Support <i class="pi pi-angle-down" style="font-size: 0.8rem;padding-left: 0.1rem;"></i>
                            </p>

                            <div class="mega-box">
                                <div class="content">
                                    <div class="grid w-full">
                                        <div class="col-12 md:col-12 lg:col-12">
                                            <router-link to="/">
                                                <div class="mega-menu-list">
                                                    <div class="mega-menu-list-info">
                                                        <h4 class="mega-menu-title">FAQ</h4>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>

                                        <div class="col-12 md:col-12 lg:col-12">
                                            <router-link to="/">
                                                <div class="mega-menu-list border-0">
                                                    <div class="mega-menu-list-info">
                                                        <h4 class="mega-menu-title">Contact Us</h4>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <ul class="header-left-menuUl header-left-menuUlBtn">
                        <li class="header-left-menuItems header-left-menuItemsServices header-btn claims-items">
                            <p class="header-btn-label"> Claims <i class="pi pi-angle-down"
                                    style="font-size: 0.8rem;padding-left: 0.1rem;"></i> </p>

                            <div class="mega-box">
                                <div class="content">
                                    <div class="grid grid-layout-me w-full m-0">
                                        <div class="col-12 md:col-6 lg:col-6 grid-layout-me-column">
                                            <router-link to="/">
                                                <div class="mega-menu-list">
                                                    <div class="mega-menu-icon">
                                                        <img src="../../assets/images/Icons/File-claim.svg">
                                                    </div>

                                                    <div class="mega-menu-list-info">
                                                        <h4 class="mega-menu-title">File a Claim</h4>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>

                                        <div class="col-12 md:col-6 lg:col-6 grid-layout-me-column">
                                            <router-link to="/">
                                                <div class="mega-menu-list">
                                                    <div class="mega-menu-icon">
                                                        <img src="../../assets/images/Icons/track.svg">
                                                    </div>

                                                    <div class="mega-menu-list-info">
                                                        <h4 class="mega-menu-title">Track Claim</h4>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>

                                        <div class="col-12 md:col-6 lg:col-6 grid-layout-me-column">
                                            <div class="mega-menu-list">
                                                <div class="mega-menu-icon">
                                                    <img src="../../assets/images/Icons/paper.svg">
                                                </div>

                                                <div class="mega-menu-list-info">
                                                    <h4 class="mega-menu-title">Claim Status</h4>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 md:col-6 lg:col-6 grid-layout-me-column">
                                            <div class="mega-menu-list">
                                                <div class="mega-menu-icon">
                                                    <img src="../../assets/images/Icons/tech-support.svg">
                                                </div>

                                                <div class="mega-menu-list-info">
                                                    <h4 class="mega-menu-title">Assistance</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="header-left-menuItems header-left-menuItemsServices header-btn claims-items login-btn-header">
                            <p class="header-btn-label"> Login </p>
                        </li>

                        <li class="header-left-menuItems header-left-menuItemsServices language-switcher">
                            <selectButton-prime v-model="selected_language" :options="items_lang" optionLabel="name" aria-labelledby="basic" @change="language" />
                        </li>
                    </ul>

                    <ul class="header-left-menuUl mobile-bar-icon">
                        <div class="bars-icon-header mobile-bar-icon" @click="visibleRight = true">
                            <i class="pi pi-bars" style="font-size: 1rem"></i>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </header>

    <sidebar-prime v-model:visible="visibleRight" position="right" class="mobile-sidebar">
        <div class="sidebar-container">
            <div class="grid header-grid m-0">
                <div class="header-left">
                    <div>
                        <router-link to="/">
                            <div class="logo">
                                <img class="site-logo-spaceDesign" src="../../assets/images/logo/logo.png">
                            </div>
                        </router-link>
                    </div>
                </div>

                <div class="mobile-sidebar-header-menuFlex mobile-sidebar-header-lang">
                    <ul class="header-left-menuUl">
                        <!-- <li class="header-left-menuItems languageDropdown">
                            <p @click="toggleLang" aria-haspopup="true" aria-controls="overlay_tmenuLang">
                                <i class="pi pi-globe"
                                    style="transform: translateY(3px);font-size: 1.2rem;padding-right: 0.1rem;"></i>
                                {{ selected_language }}
                                <i class="pi pi-angle-down"
                                    style="transform: translateY(2px);font-size: 0.8rem;padding-left: 0.1rem;"></i>
                            </p>
                            <div class="card flex justify-content-center profile-setting-dropdown">
                                <tieredMenu-prime ref="menuLang" id="overlay_tmenuLang" :model="items_lang" popup
                                    class="" />
                            </div>
                        </li> -->
                    </ul>
                </div>

                <div class="sidebar-mobile-login">
                    <div class="sidebar-login-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="24"
                            height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none"
                            stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                        </svg>
                    </div>
                    <h4>Login</h4>
                </div>


                <div class="header-right">
                    <ul class="header-left-menuUl">
                        <li class="header-left-menuItems header-left-menuItemsServices header-btn claims-items">
                            <div class="section-title-heading section-title-heading-leftSmall">
                                <h2>Claim</h2>
                            </div>

                            <div class="mega-box">
                                <div class="content">
                                    <div class="grid grid-layout-me w-full m-0">
                                        <div class="col-6 md:col-6 lg:col-6 grid-layout-me-column">
                                            <router-link to="/">
                                                <div class="mega-menu-list">
                                                    <div class="mega-menu-icon">
                                                        <img src="../../assets/images/Icons/File-claim.svg">
                                                    </div>

                                                    <div class="mega-menu-list-info">
                                                        <h4 class="mega-menu-title">File a Claim</h4>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>

                                        <div class="col-6 md:col-6 lg:col-6 grid-layout-me-column">
                                            <router-link to="/">
                                                <div class="mega-menu-list">
                                                    <div class="mega-menu-icon">
                                                        <img src="../../assets/images/Icons/track.svg">
                                                    </div>

                                                    <div class="mega-menu-list-info">
                                                        <h4 class="mega-menu-title">Track Claim</h4>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>

                                        <div class="col-6 md:col-6 lg:col-6 grid-layout-me-column">
                                            <div class="mega-menu-list">
                                                <div class="mega-menu-icon">
                                                    <img src="../../assets/images/Icons/paper.svg">
                                                </div>

                                                <div class="mega-menu-list-info">
                                                    <h4 class="mega-menu-title">Claim Status</h4>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-6 md:col-6 lg:col-6 grid-layout-me-column">
                                            <div class="mega-menu-list">
                                                <div class="mega-menu-icon">
                                                    <img src="../../assets/images/Icons/tech-support.svg">
                                                </div>

                                                <div class="mega-menu-list-info">
                                                    <h4 class="mega-menu-title">Assistance</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div class="mobile-reachUsBox">
                        <div class="section-title-heading section-title-heading-leftSmall">
                            <h2>Company</h2>
                        </div>
                        <div class="grid">
                            <div class="col-12 md:col-12 lg:col-12">
                                <div class="reach-us-list">
                                    <ul class="reach-us-list-ul">
                                        <li class="reach-us-list-li">
                                            <span> About Us </span>
                                        </li>

                                        <li class="reach-us-list-li">
                                            <span> Legal </span>
                                        </li>

                                        <li class="reach-us-list-li">
                                            <span> Privacy policy </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mobile-reachUsBox">
                        <div class="section-title-heading section-title-heading-leftSmall">
                            <h2>Support</h2>
                        </div>
                        <div class="grid">
                            <div class="col-12 md:col-12 lg:col-12">
                                <div class="reach-us-list">
                                    <ul class="reach-us-list-ul">
                                        <li class="reach-us-list-li">
                                            <span> FAQ </span>
                                        </li>

                                        <li class="reach-us-list-li">
                                            <span> Contact Us </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </sidebar-prime>
</template>

<script>
export default {
  data() {
    return {
        items_lang : [{name: 'EN', code: 'EN'},{name: 'العربية', code: 'AR'}],
        selected_language : localStorage.getItem('mebrokers_language'),
    }
  },
  created() {

  }, 
  methods : {
    language : function(value) {
        localStorage.setItem('mebrokers_language', value.value.code);
        location.reload();
    },

  },
  mounted() {
    for (let i = 0; i < this.items_lang.length; i++) {
        if(this.items_lang[i].code == this.selected_language) {
            this.selected_language = this.items_lang[i];
        } 
    }
  }
}
</script>


<script setup>
import { ref } from 'vue';

const visibleRight = ref(false);
// const value = ref('EN');
// const options = ref(['EN', 'العربية']);
</script>

<style>

</style>