@ -1,635 +1,637 @@
<template>
    <main class="motor-journey-main flow-journey">
        <div class="container flow-container">
            <div class="flow-left-column">
                <div class="top-header">
                    <router-link to="/">
                        <div class="logo">
                            <img class="site-logo-meCompare" src="../../assets/images/logo/me-logo.svg">
                        </div>
                    </router-link>
                </div>

                <div class="flow-option-back">
                    <ul class="flow-option-back-ul">
                        <!-- <li class="flow-option-back-li" @click="back">
                            <i class="pi pi-arrow-left"></i>
                            Back
                        </li> -->
                    </ul>
                </div>

                <div class="flow-option-list">
                    <ul class="flow-option-list-ul"
                        v-if="user_travel_journey.travel_type == null || (user_travel_journey.travel_type != null && user_travel_journey.travel_type.id == 2)">
                        <li class="flow-option-list-li"
                            v-bind:class="(user_travel_journey.step == 'travel_type') ? 'flow-active' : ''">
                            <div class="flow-info">
                                <div class="flow-number-badge">
                                    <span>1</span>
                                </div>
                                <div class="flow-type">
                                    <p class="flow-type-text">Travelling where</p>
                                    <h4 class="flow-type-title">{{ user_travel_journey.travel_type != null ?
                                        user_travel_journey.travel_type.name : 'Not Selected' }}</h4>
                                </div>
                            </div>
                            <div class="flow-action">
                                <div class="flow-edit-box">
                                    <span><i class="pi pi-pencil"></i></span>
                                </div>
                            </div>
                        </li>
                        <li class="flow-option-list-li"
                            v-bind:class="(user_travel_journey.step == 'travel_area') ? 'flow-active' : ''">
                            <div class="flow-info">
                                <div class="flow-number-badge">
                                    <span>2</span>
                                </div>
                                <div class="flow-type">
                                    <p class="flow-type-text">Travel Area</p>
                                    <h4 class="flow-type-title">{{ user_travel_journey.travel_area != null ?
                                        user_travel_journey.travel_area.name : 'Not Selected' }}</h4>
                                </div>
                            </div>
                            <div class="flow-action">
                                <div class="flow-edit-box">
                                    <span><i class="pi pi-pencil"></i></span>
                                </div>
                            </div>
                        </li>
                        
                        <li class="flow-option-list-li"
                            v-bind:class="(user_travel_journey.step == 'dates') ? 'flow-active' : ''">
                            <div class="flow-info">
                                <div class="flow-number-badge">
                                    <span>3</span>
                                </div>
                                <div class="flow-type">
                                    <p class="flow-type-text">Travel Date</p>
                                    <h4 class="flow-type-title">{{ user_travel_journey.departure_date != null ?
                                        moment(user_travel_journey.departure_date).format("DD MMMM YYYY") : 'Not Selected' }}</h4>
                                </div>
                            </div>
                            <div class="flow-action">
                                <div class="flow-edit-box">
                                    <span><i class="pi pi-pencil"></i></span>
                                </div>
                            </div>
                        </li>
                        <li class="flow-option-list-li"
                            v-bind:class="(user_travel_journey.step == 'travelers' || user_travel_journey.step == 'traveler_details')  ? 'flow-active' : ''">
                            <div class="flow-info">
                                <div class="flow-number-badge">
                                    <span>4</span>
                                </div>
                                <div class="flow-type">
                                    <p class="flow-type-text">Number of travelers</p>
                                    <h4 class="flow-type-title" v-if="user_travel_journey.travelers.length>0">{{
                                        user_travel_journey.travelers.length }} traveler(s)</h4>
                                    <h4 class="flow-type-title" v-else>Not Selected</h4>
                                </div>
                            </div>
                            <div class="flow-action">
                                <div class="flow-edit-box">
                                    <span><i class="pi pi-pencil"></i></span>
                                </div>
                            </div>
                        </li>
                        
                    </ul>
                    <ul class="flow-option-list-ul"
                        v-if="user_travel_journey.travel_type != null && user_travel_journey.travel_type.id == 1">
                        <li class="flow-option-list-li"
                            v-bind:class="(user_travel_journey.step == 'travel_type') ? 'flow-active' : ''">
                            <div class="flow-info">
                                <div class="flow-number-badge">
                                    <span>1</span>
                                </div>
                                <div class="flow-type">
                                    <p class="flow-type-text">Travelling where</p>
                                    <h4 class="flow-type-title">{{ user_travel_journey.travel_type != null ?
                                        user_travel_journey.travel_type.name : 'Not Selected' }}</h4>
                                </div>
                            </div>
                            <div class="flow-action">
                                <div class="flow-edit-box">
                                    <span><i class="pi pi-pencil"></i></span>
                                </div>
                            </div>
                        </li>
                        <li class="flow-option-list-li"
                            v-bind:class="(user_travel_journey.step == 'dates') ? 'flow-active' : ''">
                            <div class="flow-info">
                                <div class="flow-number-badge">
                                    <span>2</span>
                                </div>
                                <div class="flow-type">
                                    <p class="flow-type-text">Travel Date</p>
                                    <h4 class="flow-type-title">{{ user_travel_journey.departure_date != null ?
                                        moment(user_travel_journey.departure_date).format("DD MMMM YYYY") : 'Not Selected' }}</h4>
                                </div>
                            </div>
                            <div class="flow-action">
                                <div class="flow-edit-box">
                                    <span><i class="pi pi-pencil"></i></span>
                                </div>
                            </div>
                        </li>
                        <li class="flow-option-list-li"
                            v-bind:class="(user_travel_journey.step == 'travelers' || user_travel_journey.step == 'traveler_details') ? 'flow-active' : ''">
                            <div class="flow-info">
                                <div class="flow-number-badge">
                                    <span>3</span>
                                </div>
                                <div class="flow-type">
                                    <p class="flow-type-text">Number of travelers</p>
                                    <h4 class="flow-type-title" v-if="user_travel_journey.travelers.length>0">{{
                                        user_travel_journey.travelers.length }} traveler(s)</h4>
                                    <h4 class="flow-type-title" v-else>Not Selected</h4>
                                </div>
                            </div>
                            <div class="flow-action">
                                <div class="flow-edit-box">
                                    <span><i class="pi pi-pencil"></i></span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="flow-right-column">
                <div class="top-header">
                    <header class="header-container">
                        <div class="container">
                            <div class="grid header-grid m-0">
                                <div class="header-left">
                                    <router-link to="/" class="journey-header-logo">
                                        <div class="logo">
                                            <img class="site-logo-spaceDesign" src="../../assets/images/logo/logo.png">
                                        </div>
                                    </router-link>
                                </div>
                                <div class="header-right header-right-desktop">
                                    <ul class="header-left-menuUl header-left-menuUlFirst">

                                        <li
                                            class="header-left-menuItems header-left-menuItemsServices menuItemsCompany">
                                            <p>
                                                <img src="../../assets/images/Icons/headphone-icon.svg">
                                                Support
                                                <i class="pi pi-angle-down"
                                                    style="font-size: 0.8rem;padding-left: 0.1rem;"></i>
                                            </p>

                                            <div class="mega-box">
                                                <div class="content">
                                                    <div class="grid w-full">
                                                        <div class="col-12 md:col-12 lg:col-12">
                                                            <router-link to="/">
                                                                <div class="mega-menu-list">
                                                                    <div class="mega-menu-list-info">
                                                                        <h4 class="mega-menu-title">FAQ</h4>
                                                                    </div>
                                                                </div>
                                                            </router-link>
                                                        </div>

                                                        <div class="col-12 md:col-12 lg:col-12">
                                                            <router-link to="/">
                                                                <div class="mega-menu-list border-0">
                                                                    <div class="mega-menu-list-info">
                                                                        <h4 class="mega-menu-title">Contact Us</h4>
                                                                    </div>
                                                                </div>
                                                            </router-link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>

                                    <ul class="header-left-menuUl header-left-menuUlBtn">
                                        <li
                                            class="header-left-menuItems header-left-menuItemsServices language-switcher">
                                            <selectButton-prime v-model="value" :options="options"
                                                aria-labelledby="basic" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </header>

                    <div class="flow-steps-content-container">
                        <div class="progress-bar-container">
                            <div class="progress-head">
                                <p class="progress-bar-label back-btn-flow" @click="back">
                                    <i class="pi pi-arrow-left"></i>
                                    Back
                                </p>
                                <p class="progress-bar-label">{{user_travel_journey.progress_bar_percentage}}%</p>
                            </div>
                            <progressBar-prime :value="user_travel_journey.progress_bar_percentage"></progressBar-prime>
                        </div>
                        <!-- {{ user_travel_journey }} -->
                        <!-- Flow One Start -->
                        <div class="step-container one-flow travelling-where-flow" v-if="user_travel_journey.step == 'travel_type'">
                            <div class="flow-step-container border-0 p-0 mt-4">
                                <h2 class="flow-title">Travelling where?</h2>

                                <div class="gender-type-container">
                                    <div class="coverage-type-box" v-for="item in travel_types" :key="item"
                                        @click="set_value(item, 'travel_type')">
                                        <div class="insurance-type-icon">
                                            <img :src="item.icon">
                                        </div>
                                        <div class="insurance-type-info">
                                            <h2 class="m-0">{{ item.name }}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- / Flow One End -->

                        <div class="step-container one-flow height-auto flow-destinations" v-if="user_travel_journey.step == 'travel_area'">
                            <div class="flow-step-container">
                                <h2 class="flow-title">Choose your destinations</h2>

                                <!-- <div class="search-box-field">
                                    <IconField-prime iconPosition="right">
                                        <InputIcon-prime class="pi pi-search"> </InputIcon-prime>
                                        <inputText-prime v-model="value1" placeholder="Search your destination" />
                                    </IconField-prime>
                                </div> -->

                                <div class="select-list-item" v-for="item in travel_areas" :key="item"
                                    @click="set_value(item, 'travel_area')">
                                    <div class="select-list-item-box mb-0">
                                        <div class="country-img">
                                            <img src="../../assets/images/Icons/uae.png">
                                        </div>
                                        <h2 class="select-list-item-name">{{ item.name }}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Flow Two Start -->
                        <div class="step-container two-flow flow-no-border height-auto trip-type-flow"
                            v-if="user_travel_journey.step == 'travel_trip_type'">
                            <div class="flow-step-container border-0 p-0 mt-4">
                                <h2 class="flow-title">Trip type</h2>

                                <div class="insurance-type-container">
                                    <div class="coverage-type-box" v-for="item in travel_trip_types" :key="item"
                                        @click="set_value(item, 'travel_trip_type')">
                                        <div class="insurance-type-icon">
                                            <img :src="item.icon">
                                        </div>
                                        <div class="insurance-type-info">
                                            <h2 class="m-0">{{ item.name }}</h2>
                                            <p class="m-0">{{ item.description }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- / Flow Two End -->



                        <!-- Flow Four Start -->
                        <div class="step-container four-flow flow-insurance-date flow-no-border trip-dates-flow"
                            v-if="user_travel_journey.step == 'dates'">
                            <div class="flow-step-container border-0 p-0 height-auto mt-4">
                                <h2 class="flow-title">Trip Dates</h2>

                                <div class="col-12 md:col-12 lg:col-12 getQuote-form-area">
                                    <div class="getQuote-form-area-inner grid m-0">
                                        <div
                                            class="col-12 md:col-6 lg:col-6 form-fields-main calender-field-travel pl-0">
                                            <label class="mb-1 block">Departure Date</label>
                                            <span class="p-input-icon-left">
                                                <calendar-prime v-model="user_travel_journey.departure_date" showIcon
                                                    iconDisplay="input" :minDate="new Date(user_travel_journey.backend_departure_date)"
                                                    dateFormat="dd MM yy" @keypress="stop_writing_on_date($event)" />
                                            </span>
                                        </div>

                                        <div
                                            class="col-12 md:col-6 lg:col-6 form-fields-main calender-field-travel pr-0">
                                            <label class="mb-1 block">Return Date</label>
                                            <span class="p-input-icon-left">
                                                <calendar-prime v-model="user_travel_journey.return_date" showIcon
                                                    iconDisplay="input" :minDate="new Date(user_travel_journey.backend_return_date)"
                                                    dateFormat="dd MM yy" @keypress="stop_writing_on_date($event)" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button-prime class="flow-continuebtn mt-0" label="Continue"
                                @click="send_to_step('travelers')" />
                        </div>
                        <!-- / Flow Four End -->

                        <!-- Flow Five Start -->
                        <div class="step-container five-flow number-travelers-flow" v-if="user_travel_journey.step == 'travelers'">
                            <div class="flow-step-container border-0 height-auto p-0 mt-4">
                                <h2 class="flow-title">Select number of travelers</h2>

                                <div class="travellers-info-container">
                                    <div class="travellers-info-box">
                                        
                                        <div class="traveller-add-btns">
                                            <InputNumber-prime :min="0" :max="100"
                                                v-model="user_travel_journey.travelers_length"
                                                inputId="horizontal-buttons" showButtons buttonLayout="horizontal"
                                                :step="1">
                                                <template #incrementbuttonicon>
                                                    <span class="pi pi-plus" />
                                                </template>
                                                <template #decrementbuttonicon>
                                                    <span class="pi pi-minus" />
                                                </template>
                                            </InputNumber-prime>
                                        </div>
                                    </div>

                                    
                                    
                                </div>
                            </div>

                            <button-prime class="flow-continuebtn" label="Continue"
                                @click="send_to_step('traveler_details')" />
                        </div>
                        <!-- / Flow Five End -->

                        <!-- Flow Family Information Start -->
                        <div class="step-container flow-family-informations" v-if="user_travel_journey.step == 'traveler_details'">
                            <div class="flow-step-container border-0 p-0 mt-4">
                                <h2 class="flow-title">Enter Travelers details</h2>

                                <div class="travellers-info-container travellers-info-container-accordian border-0 p-0">
                                    <accordion-prime v-for="(value, key) in user_travel_journey.travelers" :key="key" expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
                                        <accordionTab-prime :header="(key==0 ? 'Traveler '+(key+1)+' (Policy Holder)' : 'Traveler '+(key+1))">
                                            <div class="col-12 md:col-12 lg:col-12 getQuote-form-area">
                                                <div class="getQuote-form-area-inner grid">
                                                    <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                                        <span class="p-input-icon-left">
                                                            <label class="input-label input-label-floating">Full
                                                                Name</label>
                                                            <inputText-prime class="form-input-fields" v-model="user_travel_journey.travelers[key].full_name"
                                                                placeholder="Enter Full Name" />
                                                        </span>
                                                    </div>
                                                    <template v-if="key==0">
                                                        <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                                            <span class="p-input-icon-left">
                                                                <label class="input-label input-label-floating">Mobile number</label>
                                                                <vue-tel-input v-model="user_travel_journey.travelers[key].mobile"
                                                                    mode="auto" :inputOptions="inputOptions"
                                                                    :dropdownOptions="dropdownOptions" validCharactersOnly
                                                                    :defaultCountry="default_country" @on-input="onInput"
                                                                    @validate="valid"></vue-tel-input>
                                                            </span>
                                                        </div>

                                                        <div class="col-12 md:col-12 lg:col-12 form-fields-main" v-if="key==0">
                                                            <span class="p-input-icon-left">
                                                                <label class="input-label input-label-floating">Email</label>
                                                                <inputText-prime v-model="user_travel_journey.travelers[key].email"
                                                                    class="form-input-fields" placeholder="Enter Your email"/>
                                                            </span>
                                                        </div>
                                                    </template>
                                                    

                                                    <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                                        <span class="p-input-icon-left">
                                                            <label class="input-label input-label-floating">Passport Number</label>
                                                            <inputText-prime class="form-input-fields" placeholder="Enter Passport Number" v-model="user_travel_journey.travelers[key].passport_number" />
                                                        </span>
                                                    </div>

                                                    <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                                        <span class="p-input-icon-left">
                                                            <label class="input-label input-label-floating">Confirm Passport Number</label>
                                                            <inputText-prime class="form-input-fields" placeholder="Confirm Passport Number" v-model="user_travel_journey.travelers[key].confirm_passport_number" />
                                                        </span>
                                                    </div>

                                                    <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                                        <span class="p-input-icon-left">
                                                            <label class="input-label input-label-floating">Date of
                                                                Birth</label>
                                                            <calendar-prime class="form-input-fields" v-model="user_travel_journey.travelers[key].date_of_birth"
                                                                showIcon iconDisplay="input" :maxDate="new Date(user_travel_journey.travelers[key].backend_date_of_birth)" dateFormat="dd MM yy" @keypress="stop_writing_on_date($event)"
                                                                placeholder="Enter DOB" />
                                                        </span>
                                                    </div>

                                                    <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                                        <span class="p-input-icon-left">
                                                            <label class="input-label input-label-floating">Nationality</label>
                                                            <dropdown-prime filter
                                                            v-model="user_travel_journey.travelers[key].nationality"
                                                            :options="nationalities" optionLabel="name" placeholder="Select Your Nationality"
                                                            class="w-full md:w-14rem" />
                                                        </span>
                                                    </div>    
                                                    
                                                    <div class="col-12 md:col-12 lg:col-12 form-fields-main">
                                                        <span class="p-input-icon-left">
                                                            <label class="input-label input-label-floating">Gender</label>
                                                            <dropdown-prime filter
                                                            v-model="user_travel_journey.travelers[key].gender"
                                                            :options="genders" optionLabel="name" placeholder="Select Your Gender"
                                                            class="w-full md:w-14rem" />
                                                        </span>
                                                    </div> 

                                                </div>
                                            </div>
                                        </accordionTab-prime>
                                    </accordion-prime>
                                    
                                    
                                </div>
                            </div>

                            <button-prime class="flow-continuebtn mt-0" label="View Quotes" :loading="loading" @click="save_and_generate_quotation" />
                        </div>
                        <!-- Flow Members End -->

                       
                    </div>
                </div>
            </div>
        </div>
    </main>
    <toast-prime />
</template>

<script>

import axios from 'axios'
import $ from 'jquery'
import moment from 'moment'

export default {
    data() {
        return {
            is_phone_number_valid : false,
            loading : false,
            nationalities : [],
            genders : [{name : 'Male'}, {name : 'Female'}],
            travel_types : [],
            travel_areas : [],
            travel_trip_types : [],
            user_travel_journey : {
                status : 'lead',
                quotation_id : null,
                quotation_number : null,
                step : 'travel_type',
                travel_type : null,
                travel_area : null,
                travel_trip_type : null,
                departure_date : null,
                return_date : null,
                backend_departure_date : new Date,
                backend_return_date : new Date,
                insurance_start_date : null,
                travelers : [],
                travelers_length : 1,
                customer : {
                    full_name : null,
                    email : null,
                    phone_number : localStorage.getItem("mebrokers.customer.phone.number") == null ? null : localStorage.getItem("mebrokers.customer.phone.number"),
                    date_of_birth : new Date(),
                    nationality : null,
                    gender : null
                },
                progress_bar_percentage : 0,
                progress_bar_per_question : 100/6,
                progress_obtained_mark : 0,
                total_questions : 6,
                add_or_subtract_number : 0
            }
        }
    },
    mounted() {
        this.get_travel_types();
        this.get_travel_trip_types();
        this.get_travel_areas();
        this.get_nationalities();
        if (this.$route.query.quotation_id != undefined && this.$route.query.quotation_id != '') {
            this.get_quotation_details(this.$route.query.quotation_id);
        } else if (localStorage.getItem('mebrokers.customer.travel.journey') != null) {
            try {
                this.user_travel_journey = JSON.parse(localStorage.getItem('mebrokers.customer.travel.journey'));
                this.user_travel_journey.customer.date_of_birth = new Date(this.user_travel_journey.customer.date_of_birth);
                this.user_travel_journey.departure_date = new Date(this.user_travel_journey.departure_date);
                this.user_travel_journey.return_date = new Date(this.user_travel_journey.return_date);
                if (this.user_travel_journey.travelers.length>0) {
                    for (let i = 0; i < this.user_travel_journey.travelers.length; i++) {
                        this.user_travel_journey.travelers[i].date_of_birth = new Date(this.user_travel_journey.travelers[i].date_of_birth);
                    }
                }    
                 
                this.calculate_percentage();
                
                
            } catch(err) {
                localStorage.removeItem('mebrokers.customer.travel.journey');
                localStorage.removeItem('mebrokers.customer.travel.plans.comparison');
            }
        }
          
        $.expr[":"].contains = $.expr.createPseudo(function(arg) {
            return function( elem ) {
                return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
            };
        });
       
    },
    methods: {
        calculate_percentage : function() {
            var num = 0;
            if (this.user_travel_journey.travel_type.id == 1) {
                if (this.user_travel_journey.step == 'travel_type') {
                    num = 0;
                }
                
                if (this.user_travel_journey.step == 'dates') {
                    num = 1;
                }
                if (this.user_travel_journey.step == 'travelers') {
                    num = 2;
                }
                if (this.user_travel_journey.step == 'traveler_details') {
                    num = 3;
                }
                
                
                this.user_travel_journey.progress_obtained_mark = this.user_travel_journey.progress_bar_per_question*num;
                this.user_travel_journey.progress_bar_percentage = Math.round(this.user_travel_journey.progress_obtained_mark/(this.user_travel_journey.total_questions*this.user_travel_journey.progress_bar_per_question)*100)
            } else if(this.user_travel_journey.travel_type.id == 2) {
                if (this.user_travel_journey.step == 'travel_type') {
                    num = 0;
                }
                if (this.user_travel_journey.step == 'travel_area') {
                    num = 1;
                }
                if (this.user_travel_journey.step == 'dates') {
                    num = 2;
                }
                if (this.user_travel_journey.step == 'travelers') {
                    num = 3;
                }
                if (this.user_travel_journey.step == 'traveler_details') {
                    num = 4;
                }
                
                this.user_travel_journey.progress_obtained_mark = this.user_travel_journey.progress_bar_per_question*num;
                this.user_travel_journey.progress_bar_percentage = Math.round(this.user_travel_journey.progress_obtained_mark/(this.user_travel_journey.total_questions*this.user_travel_journey.progress_bar_per_question)*100)
            }
        },
        save_and_generate_quotation : function() {

            var array_to_validate = this.user_travel_journey.travelers; 
            
            for (let i = 0; i < array_to_validate.length; i++) {
                if (array_to_validate[i].full_name == null) {
                    this.$toast.add({ severity: 'error', detail: "Passenger's full name is required!", life: 3000 });
                    return false;
                }
                if (array_to_validate[i].full_name.trim().split(/\s+/) && array_to_validate[i].full_name.trim().split(/\s+/).length < 2) {
                    this.$toast.add({ severity: 'error', detail: "Passenger's full name is required!", life: 3000 });
                    return false;
                }
                if (array_to_validate[i].policy_holder == 1 && !this.is_phone_number_valid) {
                    this.$toast.add({ severity: 'error', detail: 'Invalid Phone number!', life: 3000 });
                    return false; 
                }
                if (array_to_validate[i].policy_holder == 1 && (array_to_validate[i].email == null || array_to_validate[i].email == '')) {
                    this.$toast.add({ severity: 'error', detail: "email is required for policy holder!", life: 3000 });
                    return false;
                }
                if (array_to_validate[i].policy_holder == 1 && !this.validate_email(array_to_validate[i].email)) {
                    this.$toast.add({ severity: 'error', detail: 'Incorrect email!', life: 3000 });
                    return false; 
                }
                if (array_to_validate[i].passport_number == null) {
                    this.$toast.add({ severity: 'error', detail: "Passenger's passport is required!", life: 3000 });
                    return false;
                }
                if (array_to_validate[i].passport_number.length < 5) {
                    this.$toast.add({ severity: 'error', detail: "Passenger's passport length must be greater!", life: 3000 });
                    return false;
                }
                if (array_to_validate[i].passport_number != array_to_validate[i].confirm_passport_number) {
                    this.$toast.add({ severity: 'error', detail: "Confirm passport number field must match passport number !", life: 3000 });
                    return false;
                }
                if (array_to_validate[i].date_of_birth == null) {
                    this.$toast.add({ severity: 'error', detail: "Passenger's birth date is required!", life: 3000 });
                    return false;
                }
                if (array_to_validate[i].nationality == null) {
                    this.$toast.add({ severity: 'error', detail: "Passenger's nationality is required!", life: 3000 });
                    return false;
                }
                if (array_to_validate[i].gender == null) {
                    this.$toast.add({ severity: 'error', detail: "Passenger's gender is required!", life: 3000 });
                    return false;
                }
            }

            this.loading = true;

            if (localStorage.getItem('mebrokers.customer.travel.journey') != null) {
                this.user_travel_journey.quotation_id = JSON.parse(localStorage.getItem('mebrokers.customer.travel.journey')).quotation_id;
            }

            for (let i = 0; i < this.user_travel_journey.travelers.length; i++) {
                this.user_travel_journey.travelers[i].date_of_birth = moment(this.user_travel_journey.travelers[i].date_of_birth).format("YYYY-MM-DD");
            }
           


            this.user_travel_journey.customer.full_name = this.user_travel_journey.travelers[0].full_name;
            this.user_travel_journey.customer.email = this.user_travel_journey.travelers[0].email;
            this.user_travel_journey.customer.phone_number = this.user_travel_journey.travelers[0].mobile;
            this.user_travel_journey.customer.date_of_birth = this.user_travel_journey.travelers[0].date_of_birth
            this.user_travel_journey.customer.nationality = this.user_travel_journey.travelers[0].nationality.id;
            this.user_travel_journey.customer.gender = this.user_travel_journey.travelers[0].gender.name;

           
            axios.get(this.api_url+'travel/save_quotation', {
                params : {
                    customer : this.user_travel_journey.customer,
                    travel_type : this.user_travel_journey.travel_type.id,
                    travel_area : this.user_travel_journey.travel_area != null ? this.user_travel_journey.travel_area.id : 0,
                    travel_trip_type : this.user_travel_journey.travel_trip_type == null ? 0 : this.user_travel_journey.travel_trip_type.id,
                    departure_date : moment(this.user_travel_journey.departure_date).format("YYYY-MM-DD"),
                    return_date : moment(this.user_travel_journey.return_date).format("YYYY-MM-DD"),
                    traveler : this.user_travel_journey.travelers,
                    quotation_id : this.user_travel_journey.quotation_id,
                    status : this.user_travel_journey.status
                }
            }).then((response) => {
                this.user_travel_journey.quotation_id = response.data.data.quotation_id;
                this.user_travel_journey.quotation_number = response.data.data.quotation_number;
                localStorage.setItem('mebrokers.customer.travel.journey', JSON.stringify(this.user_travel_journey))
                localStorage.setItem('mebrokers.customer.phone.number', this.user_travel_journey.customer.phone_number);
                this.loading = false;
                if (this.$route.query.is_from != undefined && this.$route.query.is_from == 'admin') {
                    this.$toast.add({ severity: 'success', detail: 'Changes saved!', life: 3000 });
                } else {
                    this.$router.push({
                        path : '/travel/plan-listing/'+this.user_travel_journey.quotation_id,
                    });
                }
                
            });
            
        },
        get_travel_types : function() {
            axios.get(this.api_url+'travel/get_travel_types').then((response) => {
                this.travel_types = response.data.data;
            });
        },
        get_travel_trip_types : function() {
            axios.get(this.api_url+'travel/get_travel_trip_types').then((response) => {
                this.travel_trip_types = response.data.data;
            });
        },
        get_travel_areas : function() {
            axios.get(this.api_url+'travel/get_travel_areas').then((response) => {
                this.travel_areas = response.data.data;
            });
        },
        get_nationalities : function() {
            axios.get(this.api_url+'travel/get_nationalities').then((response) => {
                this.nationalities = response.data.data;
            });
        },
        send_to_step: function (to) {
            if (to == 'travel_area') {
                if (this.user_travel_journey.travel_type == null) {
                    this.$toast.add({ severity: 'error', detail: 'Please select the travelling where!', life: 3000 });
                    return false;
                }
            }

            if (to == 'travel_trip_type') {
                if (this.user_travel_journey.travel_type.id==2) {
                    if (this.user_travel_journey.travel_area == null) {
                        this.$toast.add({ severity: 'error', detail: 'Please select the travelling area!', life: 3000 });
                        return false;
                    }
                }
            }

            if (to == 'dates') {
                // this.user_travel_journey.departure_date = new Date;
                // this.user_travel_journey.return_date = new Date(new Date().setTime(new Date().valueOf() + 15 * 1 * 24 * 60 * 60 * 1000))
            }

            
            if (to == 'travelers') {
                if (this.user_travel_journey.departure_date == null) {
                    this.$toast.add({ severity: 'error', detail: 'Please select the departure date!', life: 3000 });
                    return false;
                }
                if (this.user_travel_journey.return_date == null) {
                    this.$toast.add({ severity: 'error', detail: 'Please select the return date!', life: 3000 });
                    return false;
                }
            }

            if (to == 'traveler_details') {
                if (this.user_travel_journey.travelers_length == null || this.user_travel_journey.travelers_length <= 0) {
                    this.$toast.add({ severity: 'error', detail: 'Travelers must be greater than 0!', life: 3000 });
                    return false;
                }
                if (this.user_travel_journey.travelers.length != this.user_travel_journey.travelers_length) {
                    this.user_travel_journey.travelers = [];
                    for (let i = 0; i < this.user_travel_journey.travelers_length; i++) {
                        this.user_travel_journey.travelers.push({
                            'full_name' : null,
                            'email' : null,
                            'mobile' : localStorage.getItem("mebrokers.customer.phone.number") == null ? null : localStorage.getItem("mebrokers.customer.phone.number"),
                            'gender' : null,
                            'nationality' : null,
                            'passport_number' : null,
                            'confirm_passport_number' : null,
                            'date_of_birth' : null,
                            'backend_date_of_birth' : i == 0 ? new Date(moment().subtract(19, 'years').format("YYYY-MM-DD")) : new Date(),
                            'policy_holder' : i == 0 ? 1 : 0
                        });
                    }
                    
                } else {
                    for (let i = 0; i < this.user_travel_journey.travelers.adults_length; i++) {
                        this.user_travel_journey.travelers[i].date_of_birth = new Date(this.user_travel_journey.travelers[i].date_of_birth)
                    }
                }
                
                
            }

            
            // if (to == 'user_detail') {

                
            // }

            // if (to != 'traveler_details') {
                this.user_travel_journey.progress_obtained_mark += this.user_travel_journey.add_or_subtract_number;
                this.user_travel_journey.progress_bar_percentage = Math.round(this.user_travel_journey.progress_obtained_mark/(this.user_travel_journey.total_questions*this.user_travel_journey.progress_bar_per_question)*100)
            // }
            
            this.user_travel_journey.step = to;
        },
        set_value : function(item, type) {
            if (type == 'travel_type') {
                this.user_travel_journey.travel_type = item;
                if (this.user_travel_journey.travel_type.id == 1) {
                    this.user_travel_journey.progress_bar_percentage = 0;
                    this.user_travel_journey.progress_bar_per_question = 100/3;
                    this.user_travel_journey.progress_obtained_mark = 0;
                    this.user_travel_journey.total_questions = 3;
                    this.user_travel_journey.add_or_subtract_number = 33.33;
                    this.send_to_step('dates');
                } else if(this.user_travel_journey.travel_type.id == 2) {
                    this.user_travel_journey.progress_bar_percentage = 0;
                    this.user_travel_journey.progress_bar_per_question = 100/4;
                    this.user_travel_journey.progress_obtained_mark = 0;
                    this.user_travel_journey.total_questions = 4;
                    this.user_travel_journey.add_or_subtract_number = 25;
                    this.send_to_step('travel_area');
                }
            }
            if (type == 'travel_area') {
                this.user_travel_journey.travel_area = item;
                this.send_to_step('dates');
            }
            if (type == 'travel_trip_type') {
                this.user_travel_journey.travel_trip_type = item;
                this.send_to_step('dates');
            }
        },
        back: function () {
            if (this.user_travel_journey.travel_type != null && this.user_travel_journey.travel_type.id == 1) {
                if (this.user_travel_journey.step == 'user_detail') {
                    this.user_travel_journey.step = 'traveler_details';
                } else if (this.user_travel_journey.step == 'traveler_details') {
                    this.user_travel_journey.step = 'travelers';
                } else if (this.user_travel_journey.step == 'travelers') {
                    this.user_travel_journey.step = 'dates';
                } else if (this.user_travel_journey.step == 'dates') {
                    this.user_travel_journey.step = 'travel_type';
                }  else if (this.user_travel_journey.step == 'travel_trip_type') {
                    this.user_travel_journey.step = 'travel_type';
                } else if (this.user_travel_journey.step == 'travel_type') {
                    this.$router.push({
                        name : 'travel',
                    });
                }
            } else if (this.user_travel_journey.travel_type != null && this.user_travel_journey.travel_type.id == 2) {
                if (this.user_travel_journey.step == 'user_detail') {
                    this.user_travel_journey.step = 'traveler_details';
                } else if (this.user_travel_journey.step == 'traveler_details') {
                    this.user_travel_journey.step = 'travelers';
                } else if (this.user_travel_journey.step == 'travelers') {
                    this.user_travel_journey.step = 'dates';
                } else if (this.user_travel_journey.step == 'dates') {
                    this.user_travel_journey.step = 'travel_area';
                }  else if (this.user_travel_journey.step == 'travel_trip_type') {
                    this.user_travel_journey.step = 'travel_area';
                }  else if (this.user_travel_journey.step == 'travel_area') {
                    this.user_travel_journey.step = 'travel_type';
                } else if (this.user_travel_journey.step == 'travel_type') {
                    this.$router.push({
                        name : 'travel',
                    });
                }
            } else if (this.user_travel_journey.travel_type == null) {
                if (this.user_travel_journey.step == 'travel_type') {
                    this.$router.push({
                        name : 'travel',
                    });
                }
            }
            
            // if (this.user_travel_journey.step == 'travel_type') {
            //     this.$router.push({
            //         name : 'travel',
            //     });
            // }

            this.user_travel_journey.progress_obtained_mark -= this.user_travel_journey.add_or_subtract_number;
            this.user_travel_journey.progress_bar_percentage = Math.round(this.user_travel_journey.progress_obtained_mark/(this.user_travel_journey.total_questions*this.user_travel_journey.progress_bar_per_question)*100)

            
            
        },
        stop_writing_on_date : function(eve) {
            eve.preventDefault();
        },
        onInput : function(phone, obj) {
            if(obj.valid) {
                this.is_phone_number_valid = true;
            } else {
                this.is_phone_number_valid = false;
            }
        },
        valid : function(obj) {
            if (obj.valid != undefined) {
                if(obj.valid) {
                    this.is_phone_number_valid = true;
                } else {
                    this.is_phone_number_valid = false;
                }
            } else {
                this.is_phone_number_valid = false;
            }
        },
        validate_email : function (email) {
            // eslint-disable-next-line
            return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        },
        number_with_commas : function (x) {
            // eslint-disable-next-line
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }
}
</script>

<script setup>
import { ref } from 'vue';

const value = ref('EN');
const options = ref(['EN', 'العربية']);

</script>

<style></style>
