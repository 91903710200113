<template>
    <div class="get-agent-help-container">
        <div class="agent-help-box">
            <div class="agent-image">
                <img src="../assets/images/amyelsner.png">
            </div>
            <div class="agentHelp-rightInfo">
                <h2>Chat with support</h2>
                <p>Get instant assistance from our dedicated team. </p>
                <a :href="'https://wa.me/'+company_whatsapp_number"><button-prime class="support-chatnow-btn" label="Chat Now" icon="pi pi-whatsapp" /></a>
            </div>
        </div>
    </div>
</template>