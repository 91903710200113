<template>
  <router-view :key="$route.path"></router-view>
</template>

<script>
import $ from 'jquery'
export default {
  name: 'App',

  data() {
    return {}
  },
  mounted() {
    if (localStorage.getItem('mebrokers_language') == 'EN') {
      $('body').removeClass('direction-rtl');
    } else if (localStorage.getItem('mebrokers_language') == 'AR') {
      $('body').addClass('direction-rtl');
    }
  } 
  
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
