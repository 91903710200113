<template>
    <HeaderJourney />

    <div class="main-checkout-page">
        <div class="container">
            <div class="grid ml-0 mr-0">
                <div class="col-12 md:col-12 lg:col-12">
                    <div class="progress-bar-container">
                        <div class="progress-head">
                            <!-- <router-link to="/upload-document">
                                <p class="progress-bar-label back-btn-flow">
                                    <i class="pi pi-arrow-left"></i>
                                    Back
                                </p>
                            </router-link> -->
                        </div>
                    </div>
                </div>
            </div>
            <message-prime severity="error" v-if="transaction.error_message!=null">Transaction Error: {{ transaction.error_message }}, Please Try again!</message-prime>
            <div class="grid ml-0 mr-0">

                <div class="col-12 md:col-6 lg:col-7">
                    <h3 class="topborder mb-1"><span>Check Out</span></h3>
                    <p class="text-center mb-0 mt-0">How would you like to pay for your quote </p>

                    <div class="iframe-loader" v-if="iframe_url==null || load_extra_time==true">
                        <ProgressSpinner-prime/>
                    </div>
                    <iframe id="telr" v-if="iframe_url != null || load_extra_time==false" :src="iframe_url"></iframe>
                </div>

                <!-- <div class="col-12 md:col-6 lg:col-7">
                    <h3 class="topborder mb-1"><span>Check Out</span></h3>
                    <p class="text-center mb-0 mt-0">How would you like to pay for your quote </p>

                    <div class="left-checkout-box">
                        <div class="checkout-selected-box hidden">
                            <div class="flex radio-list-item-area">
                                <div class="checkout-radio-box">
                                    <radioButton-prime v-model="filter1" inputId="one" name="one" value="one" />
                                    <div class="radio-info-text-box">
                                        <label for="one" class="">Card Payment</label>
                                        <p>Make a payment using credit or debit card securely on our website</p>
                                    </div>
                                </div>
                                <div class="radio-right-image">
                                    <img src="../../assets/images/Icons/cards-image.png">
                                </div>
                            </div>

                            <div class="multiple-cards-option-list">
                                <span class="add-card-btn">Add a new card</span>

                                <div class="info-box-checkout-container">
                                    <div class="info-box-checkout default">
                                        <div class="info-box-checkout-left">
                                            <div class="card-image">
                                                <img src="../../assets/images/Icons/card1.png">
                                            </div>
                                            <div class="card-info">
                                                <h2>VISA <span>ending in 8783</span></h2>
                                                <p><span>Expires:</span> 12/2029</p>
                                                <p>Defaullt</p>
                                            </div>
                                        </div>
                                        <div class="info-box-checkout-right info-box-checkout-actionBtn">
                                            <span>Edit</span>
                                            <span>Delete</span>
                                        </div>
                                    </div>

                                    <div class="info-box-checkout">
                                        <div class="info-box-checkout-left">
                                            <div class="card-image">
                                                <img src="../../assets/images/Icons/card2.png">
                                            </div>
                                            <div class="card-info">
                                                <h2>VISA <span>ending in 8783</span></h2>
                                                <p><span>Expires:</span> 12/2029</p>
                                            </div>
                                        </div>
                                        <div class="info-box-checkout-right info-box-checkout-actionBtn">
                                            <span>Edit</span>
                                            <span>Delete</span>
                                        </div>
                                    </div>

                                    <div class="info-box-checkout">
                                        <div class="info-box-checkout-left">
                                            <div class="card-image">
                                                <img src="../../assets/images/Icons/card3.png">
                                            </div>
                                            <div class="card-info">
                                                <h2>VISA <span>ending in 8783</span></h2>
                                                <p><span>Expires:</span> 12/2029</p>
                                            </div>
                                        </div>
                                        <div class="info-box-checkout-right info-box-checkout-actionBtn">
                                            <span>Edit</span>
                                            <span>Delete</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="checkout-form-area">
                                <h3 class="topborder mb-1 mt-5 text-left"><span>Add new card</span></h3>

                                <div class="col-12 md:col-12 lg:col-12 getQuote-form-area">
                                    <div class="getQuote-form-area-inner grid">
                                        <div class="col-12 md:col-12 lg:col-12 form-fields-main p-0">
                                            <span class="p-input-icon-left">
                                                <label class="input-label input-label-floating">Card Holder
                                                    Number*</label>
                                                <inputText-prime type="number" class="form-input-fields"
                                                    placeholder="1234 5678 9102 2547" />
                                            </span>
                                        </div>

                                        <div class="col-12 md:col-6 lg:col-6 form-fields-main pl-0">
                                            <span class="p-input-icon-left">
                                                <label class="input-label input-label-floating">Expiry Date*</label>
                                                <inputText-prime class="form-input-fields" placeholder="10/27" />
                                            </span>
                                        </div>

                                        <div class="col-12 md:col-6 lg:col-6 form-fields-main pr-0">
                                            <span class="p-input-icon-left">
                                                <label class="input-label input-label-floating">Security Code*</label>
                                                <inputText-prime class="form-input-fields" placeholder="123" />
                                            </span>
                                        </div>

                                        <div class="col-12 md:col-12 lg:col-12 form-fields-main p-0">
                                            <span class="p-input-icon-left">
                                                <label class="input-label input-label-floating">Card Holder
                                                    Name*</label>
                                                <inputText-prime class="form-input-fields" placeholder="John Deck" />
                                            </span>
                                        </div>
                                    </div>

                                    <button-prime class="flow-continuebtn mt-0 mb-0" label="Add a new card" />
                                </div>
                            </div>
                        </div>

                        <div class="checkout-selected-box bg-transparent">
                            <div class="flex radio-list-item-area">
                                <div class="checkout-radio-box">
                                    <radioButton-prime v-model="filter1" inputId="two" name="two" value="two" />
                                    <div class="radio-info-text-box">
                                        <label for="two" class="">Apple Pay</label>
                                        <p>Make a payment using credit or debit card securely on our website</p>
                                    </div>
                                </div>
                                <div class="radio-right-image">
                                    <img src="../../assets/images/Icons/pay.png">
                                </div>
                            </div>
                        </div>

                        <div class="checkout-selected-box bg-transparent">
                            <div class="flex radio-list-item-area">
                                <div class="checkout-radio-box">
                                    <radioButton-prime v-model="filter1" inputId="three" name="three" value="three" />
                                    <div class="radio-info-text-box">
                                        <label for="three" class="">Pay in instalments with TABBY</label>
                                        <p>Make a payment using credit or debit card securely on our website</p>
                                    </div>
                                </div>
                                <div class="radio-right-image">
                                    <img src="../../assets/images/Icons/tabby.png">
                                </div>
                            </div>
                        </div>
                    </div>
                    <template v-if="('transaction_details' in quotation) && quotation.transaction_details != ''">
                        <button-prime class="flow-continuebtn" label="Pay Now" @click="pay_now" />
                    </template>
                    
                </div> -->

                <div class="col-12 md:col-6 lg:col-5 order">
                    <div class="summary-box-checkout">
                        <h3 class="topborder mt-0"><span>Summary</span></h3>

                        <div class="summary-price-info-list">
                            <h5>Payment Details</h5>
                        </div>

                        <div class="summary-price-info-list">
                            <p class="inline difwidth center">Yearly Premium</p>
                            <h5 class="inline alignright">AED {{ ('premium' in quotation) ? quotation.premium : '-'}}</h5>
                        </div>

                        <div class="summary-price-info-list">
                            <p class="inline difwidth center">VAT</p>
                            <h5 class="inline alignright">AED {{ ('tax' in quotation) ? quotation.tax : '-'}}</h5>
                        </div>

                        <div class="summary-price-info-list" v-if="('insurance_type_id' in quotation) && quotation.insurance_type_id == 1">
                            <p class="inline difwidth center">Excess</p>
                            <h5 class="inline alignright">AED {{ ('excess' in quotation) ? quotation.excess : '-'}}</h5>
                        </div>
                        
                        <template v-if="('additional_coverages' in quotation) && quotation.additional_coverages.length>0">
                            <div class="summary-price-info-list">
                                <h5>Additional Add-ons</h5>
                            </div>
                            <div class="summary-price-info-list" v-for="item in quotation.additional_coverages" :key="item">
                                <p class="inline difwidth center">{{ item.benefit_name }} {{ item.benefit_value == '' ? '' : ' - '+item.benefit_value }}</p>
                                <h5 class="inline alignright">AED {{ item.benefit_amount }}</h5>
                            </div>
                        </template>

                        <div class="summary-price-info-list">
                            <h5>Total Amount to Pay</h5>
                        </div>

                        <div class="summary-price-info-list">
                            <p class="inline difwidth center">Total</p>
                            <h5 class="inline alignright">AED {{ this.total_price }}</h5>
                        </div>

                        <!-- <div class="info-box-checkout">
                            <div class="info-box-checkout-left">
                                <h2>Flexible credit options?</h2>

                                <h3>Pay in monthly instalments with Tabby!</h3>
                                <p>From AED 50.09 per month over 3 months*</p>
                            </div>
                            <div class="info-box-checkout-right">
                                <a href="#">Find out more</a>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderJourney from '../Header/HeaderJourney.vue'
import axios from 'axios'
// import moment from 'moment'
export default {
    data() {
        return {
            load_extra_time : true,
            iframe_url : null,
            quotation : {},
            total_price : 0,
            transaction : {
                error_message : null,
            }
        }
    },
    components: {
        HeaderJourney
    },
    mounted() {
        if (this.$route.query.error_msg != undefined && this.$route.query.error_msg != '') {
            this.transaction.error_message = this.$route.query.error_msg;
        } 
        this.get_quotation_details();
        this.get_payment_url();
    },
    methods : {
        get_quotation_details : function() {
            axios.get(this.api_url+'medical/get_quotation_details', {
                params : {
                    quotation_id : this.$route.params.quotation_id
                }
            }).then((response) => {
                this.quotation = response.data.data.quotation;
                for (let i = 0; i < this.quotation.additional_coverages.length; i++) {
                    this.total_price += this.quotation.additional_coverages[i].benefit_amount != "" && this.quotation.additional_coverages[i].benefit_amount != null ? parseFloat(this.quotation.additional_coverages[i].benefit_amount) : 0
                }
                this.total_price += parseFloat(this.quotation.premium);
                this.total_price += parseFloat(this.quotation.tax);
                this.total_price = parseFloat(this.total_price).toFixed(2);
                if (this.quotation.transaction_details != null &&this.quotation.transaction_details != '' && this.quotation.transaction_details.approved == 1) {
                    this.$router.push({
                        name : 'medical-home',
                    });
                }
            });
        },
        pay_now : function() {
            window.location.href = this.api_url+'payment/telr/redirect_to_payment_link/medical/'+this.$route.params.quotation_id;
        },
        get_payment_url : function () {
            axios.get(this.api_url+'payment/get_payment_url', {
                params : {
                    quotation_id : this.$route.params.quotation_id,
                    product : 'medical'
                }
            }).then((response) => {
                this.iframe_url = response.data.data.payment_url;
                setTimeout(() => {
                    this.load_extra_time = false;
                }, "3000");
            });
        }
    }
}
</script>



<style>

</style>