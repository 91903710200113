<template>
    <HeaderJourney />

    <div class="medical-network-page">
        <div class="container">
            <div class="grid ml-0 mr-0">
                <div class="col-12 md:col-12 lg:col-12">
                    <h2 class="network-medical-title">Network Map</h2>
                </div>

                <!-- <div class="col-12 md:col-12 lg:col-12 network-search-box">
                    <inputText-prime type="text" placeholder="Search network" @keyup="searchHospitals" v-model="searchhospital" />
                </div> -->
            </div>

            <div class="grid ml-0 mr-0 sort-dropdown">
                <div class="col-12 md:col-12 lg:col-12">
                    <label>Filter By</label>
                    <dropdown-prime :options="filters_data"  showClear v-model="selected_filter" @change="get_medical_hospitals" optionLabel="type"
                        placeholder="All" class="w-full" />
                </div>
            </div>

            <div class="grid ml-0 mr-0">
               
                <div class="card">
                    <DataTable-prime v-model:filters="filters" :value="hospitals" paginator :rows="10">
                        <template #header>
                            <div class="flex justify-content-end">
                                <IconField-prime iconPosition="left">
                                    <InputIcon-prime>
                                        <i class="pi pi-search" />
                                    </InputIcon-prime>
                                    <inputText-prime v-model="filters['global'].value" placeholder="Keyword Search" />
                                </IconField-prime>
                            </div>
                        </template>
                        <template #empty> No Hospitals found. </template>
                        <template #loading> Loading hospitals data. Please wait. </template>
                        <Column-prime field="name" header="Name" style="width: 100%">
                            <template #body="slotProps">
                            <div class="col-12 md:col-8 lg:col-8">
                                <div class="network-medical-left-container">
                                    <div class="network-list hospitals-list">
                                        <div class="network-info">
                                            <h2 class="network-name">{{ slotProps.data.name }} </h2>
                                            <p class="network-area">
                                                <span class="inner-list-icon"></span>
                                                {{ slotProps.data.address }} <br> {{ slotProps.data.region }} {{ slotProps.data.country }}
                                            </p>
                                            <p class="network-number">
                                                <span class="inner-list-icon"></span>
                                                {{ slotProps.data.phone }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                            
                        </Column-prime> 
                    </DataTable-prime>
                </div>

                <!-- <div class="col-12 md:col-4 lg:col-4">
                    <template v-if="hospitals!=null">
                        <div class="network-medical-right-container" v-if="hospitals.length>0" v-html="hospital_map_html"></div>
                    </template>
                </div> -->
            </div>

            <!-- <div class="grid ml-0 mr-0 pagination-box">
                <div class="col-12 md:col-12 lg:col-12">
                    <paginator-prime v-if="hospitals.length>10" @page="page" v-model:first="current_page" :rows="10" :totalRecords="hospitals.length"></paginator-prime>
                </div>
            </div> -->
            <!-- <div class="grid ml-0 mr-0" v-if="hospitals == null">
                <div class="col-12 md:col-12 lg:col-12">
                    <h2 class="network-medical-title">Hospitals not found!</h2>
                </div>
            </div> -->
        </div>
    </div>

    <FooterView />
</template>

<script>
import HeaderJourney from '../Header/HeaderJourney.vue'
import { FilterMatchMode } from 'primevue/api';
import axios from 'axios'
import $ from 'jquery'
// import moment from 'moment'
export default {
    components: {
        HeaderJourney,
    },
    data() {
        return {
            hospitals : [],
            hospital_map_html : "",
            searchhospital : null,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            filters_data : [],
            selected_filter : null
        }

    },
    mounted() {
        $.expr[":"].contains = $.expr.createPseudo(function(arg) {
            return function( elem ) {
                return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
            };
        });
        this.get_medical_hospitals();
        this.get_filters();
    },
    methods: {
        get_medical_hospitals : function() {
            axios.get(this.api_url+'medical/get_medical_plan_hospitals', {
                params : {
                    plan_id : this.$route.params.plan_id,
                    type : this.selected_filter == null ? null : this.selected_filter.type
                }
            }).then((response) => {
                this.hospitals = response.data.data.hospitals;
            });
        },
        // show_map : function(data) {
        //     this.hospital_map_html = data.map_html;
        // },
        searchHospitals : function() {
            $('.hospitals-list').removeClass('hidden');
            $('.hospitals-list:not(:contains('+ this.searchhospital +'))').addClass('hidden');
        },
        get_filters : function() {
            axios.get(this.api_url+'medical/get_medical_hospital_page_filters',).then((response) => {
                this.filters_data = response.data.data.filters;
            });
        },
        
    }
}
</script>