<template>
  <HeaderView />

  <main>
    <div class="main-content home-content">
      <section class="main-banner-area bg-image-none main-home-banner">
        <div class="container">
          <div class="main-banner-container">
            <div class="main-banner-title">
              <h2>
                Insurance coverage with ME Compare
              </h2>
            </div>

            <div class="section-gradient width-boxed">
              <div class="grid w-full">
                <div class="col-12 md:col-6 lg:col-6 motor-box-home">
                  <h2 class="inner-heading-title">
                    Motor Insurance
                    <p class="comming-soon-label">COMMING SOON</p>
                  </h2>
                  <p class="inner-para-text">Provide your vehicle sequence number or customs card number.</p>

                  <router-link to="/motor" class="pointer-events-none opacity-50">
                    <button-prime class="inner-sec-btn" aria-label="" label="Get Quote!"></button-prime>
                  </router-link>
                </div>

                <div class="col-12 md:col-6 lg:col-6">
                  <div class="inner-section-image third-party-liability">
                    <img src="../assets/images/third-party-liability.png">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-padding mobile-padding-bottom-0 pt-0">
        <div class="container">
          <div class="tabs-section-area width-boxed mt-0">
            <div class="grid w-full">
              <div class="col-12 md:col-4 lg:col-4 insurance-service-box">
                <div class="info-box-column">
                  <div>
                    <h4 class="info-box-title">Travel Insurance</h4>
                    <p class="info-box-description">Provide your vehicle sequence number or customs card number.
                    </p>
                    <router-link to="/travel">
                      <button-prime class="inner-sec-btn" aria-label="" label="Get Quote!"></button-prime>
                    </router-link>
                  </div>

                  <div class="insurance-services-box-image">
                    <img src="../assets/images/travel-ins.png">
                  </div>
                </div>
              </div>

              <div class="col-12 md:col-4 lg:col-4 insurance-service-box">
                <div class="info-box-column">
                  <div>
                    <h4 class="info-box-title">
                      Medical Insurance
                      <p class="comming-soon-label">COMMING SOON</p>
                    </h4>
                    <p class="info-box-description">Provide your vehicle sequence number or customs card number.</p>
                    <router-link to="/medical" class="pointer-events-none opacity-50">
                      <button-prime class="inner-sec-btn" aria-label="" label="Get Quote!"></button-prime>
                    </router-link>
                  </div>

                  <div class="insurance-services-box-image">
                    <img src="../assets/images/emergencycare.png">
                  </div>
                </div>
              </div>

              <div class="col-12 md:col-4 lg:col-4 insurance-service-box">
                <div class="info-box-column">
                  <div>
                    <h4 class="info-box-title">
                      Home Insurance
                      <p class="comming-soon-label">COMMING SOON</p>
                    </h4>
                    <p class="info-box-description">
                      Provide your vehicle sequence number or customs card number.
                    </p>

                    <router-link to="/" class="pointer-events-none opacity-50">
                      <button-prime class="inner-sec-btn" aria-label="" label="Get Quote!"></button-prime>
                    </router-link>
                  </div>

                  <div class="insurance-services-box-image">
                    <img src="../assets/images/home-ins.png">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-padding mobile-padding-bottom-0 our-trusted-partners-section">
        <div class="container">
          <div class="section-title-heading">
            <h2 class="mb-3">Our Trusted Partners</h2>
          </div>

          <div class="grid w-full justify-content-center mt-3">
            <swiper :slides-per-view="4" :space-between="0" setWrapperSize="ture" :navigation="false" :loop="true" 
                :autoplay="{
                  delay: 2500,
                  disableOnInteraction: false,
                }"
                :pagination="pagination" :modules="modules" :breakpoints="{
                        '@0.00': { slidesPerView: 1, spaceBetween: 0 },
                        '@0.75': { slidesPerView: 2, spaceBetween: 0 },
                        '@1.00': { slidesPerView: 3, spaceBetween: 0 },
                        '@1.50': { slidesPerView: 4, spaceBetween: 0 },
                }" @swiper="onSwiper" @slideChange="onSlideChange">
                <swiper-slide>
                  <div class="col-12">
                    <div class="trusted-partner-box-image">
                      <img src="../assets/images/partner1.png">
                    </div>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div class="col-12">
                    <div class="trusted-partner-box-image">
                      <img src="../assets/images/partner2.png">
                    </div>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div class="col-12">
                    <div class="trusted-partner-box-image">
                      <img src="../assets/images/partner3.png">
                    </div>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div class="col-12">
                    <div class="trusted-partner-box-image">
                      <img src="../assets/images/partner4.png">
                    </div>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div class="col-12">
                    <div class="trusted-partner-box-image">
                      <img src="../assets/images/partner5.png">
                    </div>
                  </div>
                </swiper-slide>
            </swiper>
          </div>
        </div>
      </section>

      <section class="section-padding mobile-padding-bottom-0">
        <div class="container">
          <div class="tabs-section-area width-boxed">
            <div class="grid w-full">
              <div class="col-12 md:col-4 lg:col-4 insurance-since-box-first">
                <div class="info-box-column">
                  <h4 class="info-box-title">1</h4>
                  <p class="info-box-description">Digital Broker in UAE</p>
                </div>
              </div>

              <div class="col-12 md:col-4 lg:col-4 insurance-since-box-middle">
                <div class="info-box-column">
                  <h4 class="info-box-title">800,000</h4>
                  <p class="info-box-description">Clients served in UAE
                  </p>
                </div>

                <div class="info-box-column">
                  <h4 class="info-box-title">4.8
                    <rating-prime v-model="valuerating" :cancel="false" />
                  </h4>
                  <p class="info-box-description">Customer Ratings
                  </p>
                </div>
              </div>

              <div class="col-12 md:col-4 lg:col-4 insurance-since-box-last">
                <div class="info-box-column">
                  <h4 class="info-box-title">
                    ME Compare
                    Insurance
                    since 1990 in
                    UAE</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-padding">
        <div class="container">
          <div class="section-title-heading">
            <h2 class="mb-3">Explore ME Compare Insurance</h2>
          </div>

          <div class="why-choose-inner-area width-boxed">
            <div class="grid w-full">
              <div class="col-12 md:col-4 lg:col-4 explore-box">
                <div class="info-box-column">
                  <div class="icon-box-area">
                    <img src="../assets/images/explore1.svg">
                  </div>
                  <h4 class="info-box-title">Quick Quote Tool</h4>
                  <p class="info-box-description">Get instant quotes and compare policies effortlessly.</p>
                </div>
              </div>

              <div class="col-12 md:col-4 lg:col-4 explore-box">
                <div class="info-box-column">
                  <div class="icon-box-area">
                    <img src="../assets/images/explore2.svg">
                  </div>
                  <h4 class="info-box-title">Easy Claims Management</h4>
                  <p class="info-box-description"> File and manage claims seamlessly through our online platform.
                  </p>
                </div>
              </div>

              <div class="col-12 md:col-4 lg:col-4 explore-box">
                <div class="info-box-column">
                  <div class="icon-box-area">
                    <img src="../assets/images/explore3.svg">
                  </div>
                  <h4 class="info-box-title">Customer Support</h4>
                  <p class="info-box-description">Dedicated assistance for any inquiries or guidance you need.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-padding pay-later-box-section">
        <div class="container">
          <div class="section-title-heading">
            <h2 class="mb-4">Insure Now, Pay Later</h2>
          </div>

          <div class="section-gradient width-boxed pay-later-box mt-6">
            <div class="pay-later-img">
              <img src="../assets/images/pay-later.png">
            </div>
          </div>
        </div>
      </section>

      <section class="section-padding insure-now-pay meCompare-family-section">
        <div class="container">
          <div class="grid width-boxed">
            <div class="col-12 md:col-6 lg:col-6 family-member-box-right">
              <h2 class="inner-heading-title inner-heading-title-tabby"> Join the MeComapre <br> Family Today </h2>
              <p class="inner-para-text"> At MeComapre insurance, we believe in providing more than just insurance; we're your dedicated partner in added value. Our membership program offers you unparalleled access to discounts from service providers related to your purchased insurance. </p>

              <button-prime class="become-a-member-btn" aria-label="" label="Become a Member"></button-prime>
            </div>

            <div class="col-12 md:col-6 lg:col-6 relative">
              <div class="family-member-grid-area">
                 <div class="members-info-box">
                  <div class="become-member-icon">
                    <img src="../assets/images/become-family-member-icons/lighting.svg">
                  </div>
                  <h4 class="member-info-title">
                    Battery and Tires
                  </h4>
                 </div>

                 <div class="members-info-box">
                  <div class="become-member-icon">
                    <img src="../assets/images/become-family-member-icons/transport.svg">
                  </div>
                  <h4 class="member-info-title">
                    Roadside Assistance
                  </h4>
                 </div>

                 <div class="members-info-box">
                  <div class="become-member-icon">
                    <img src="../assets/images/become-family-member-icons/car.svg">
                  </div>
                  <h4 class="member-info-title">
                    Car Detailing
                  </h4>
                 </div>

                 <div class="members-info-box">
                  <div class="become-member-icon">
                    <img src="../assets/images/become-family-member-icons/vacuum.svg">
                  </div>
                  <h4 class="member-info-title">
                    Home cleaning
                  </h4>
                 </div>

                 <div class="members-info-box">
                  <div class="become-member-icon">
                    <img src="../assets/images/become-family-member-icons/hydrated-skin.svg">
                  </div>
                  <h4 class="member-info-title">
                    Dermatology / Facial Discounts 
                  </h4>
                 </div>

                 <div class="members-info-box">
                  <div class="become-member-icon">
                    <img src="../assets/images/become-family-member-icons/air-conditioner.svg">
                  </div>
                  <h4 class="member-info-title">
                    AC Service
                  </h4>
                 </div>

                 <div class="members-info-box">
                  <div class="become-member-icon">
                    <img src="../assets/images/become-family-member-icons/sanitizer.svg">
                  </div>
                  <h4 class="member-info-title">
                    Pest Control
                  </h4>
                 </div>

                 <div class="members-info-box">
                  <div class="become-member-icon">
                    <img src="../assets/images/become-family-member-icons/gardening.svg">
                  </div>
                  <h4 class="member-info-title">
                    Gardening
                  </h4>
                 </div>

                 <div class="members-info-box">
                  <div class="become-member-icon">
                    <img src="../assets/images/become-family-member-icons/travel-agency.svg">
                  </div>
                  <h4 class="member-info-title">
                    Travel Agents
                  </h4>
                 </div>

                 <div class="members-info-box">
                  <div class="become-member-icon">
                    <img src="../assets/images/become-family-member-icons/car-rental.svg">
                  </div>
                  <h4 class="member-info-title">
                    Car Rental
                  </h4>
                 </div>

                 <div class="members-info-box">
                  <div class="become-member-icon">
                    <img src="../assets/images/become-family-member-icons/weights.svg">
                  </div>
                  <h4 class="member-info-title">
                    Gym Membership
                  </h4>
                 </div>

                 <div class="members-info-box">
                  <div class="become-member-icon">
                    <img src="../assets/images/become-family-member-icons/medicine.svg">
                  </div>
                  <h4 class="member-info-title">
                    Pharmacy Discounts
                  </h4>
                 </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-padding intelligent-support-section">
        <div class="container">
          <div class="section-title-heading">
            <h2 class="mb-3">Intelligent Support, Instant Solutions</h2>
          </div>

          <div class="why-choose-inner-area width-boxed">
            <div class="grid w-full">
              <div class="col-12 md:col-6 lg:col-6 p-0">
                <div class="grid w-full">
                  <div class="col-12 md:col-12 lg:col-12 explore-box">
                    <div class="info-box-column">
                      <div class="icon-box-area">
                        <img src="../assets/images/intelligent-support1.svg">
                      </div>

                      <div class="intelligent-support-info">
                        <h4 class="info-box-title">Claim Management</h4>
                        <p class="info-box-description">Submit claims online <br> Track your claim seamlessly</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 md:col-12 lg:col-12 explore-box">
                    <div class="info-box-column">
                      <div class="icon-box-area">
                        <img src="../assets/images/intelligent-support2.svg">
                      </div>

                      <div class="intelligent-support-info">
                        <h4 class="info-box-title">Insurance Management</h4>
                        <p class="info-box-description">Buy insurance policies in 3 steps <br> Renew insurance policies
                          hassle free</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 md:col-12 lg:col-12 explore-box">
                    <div class="info-box-column">
                      <div class="icon-box-area">
                        <img src="../assets/images/intelligent-support3.svg">
                      </div>

                      <div class="intelligent-support-info">
                        <h4 class="info-box-title">AI WhatsApp Notification</h4>
                        <p class="info-box-description">Receive your claim and policy updates in real-time with our AI
                          assistant</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 md:col-6 lg:col-6 p-0">
                <div class="intelligent-support-section-img">
                  <img src="../assets/images/mobile-image.png">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-padding effortless-claims-section effortless-claims-section-home">
        <div class="container">
          <div class="section-gradient width-boxed">
            <div class="grid w-full">
              <div class="col-12 md:col-6 lg:col-6 relative inner-section-image-mobile-img">
                <div class="inner-section-image-mobile">
                  <img src="../assets/images/white-mobile-2.png">
                </div>
              </div>

              <div class="col-12 md:col-6 lg:col-6">
                <h2 class="inner-heading-title">Effortless Claims with Our <br> Web App</h2>
                <p class="inner-para-text">Provide your vehicle sequence number or customs card number.</p>

                <ul class="effortless-claims-ul">
                  <li>
                    <i class="pi pi-check" style="font-size: 1rem"></i>
                    <span>Streamline claims submission</span>
                  </li>
                  <li>
                    <i class="pi pi-check" style="font-size: 1rem"></i>
                    <span>Stay updated on claim progress</span>
                  </li>
                  <li>
                    <i class="pi pi-check" style="font-size: 1rem"></i>
                    <span>Download forms and documents</span>
                  </li>
                </ul>

                <button-prime class="file-a-claim" aria-label="" label="File a Claim"></button-prime>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-padding insure-now-pay">
        <div class="container">
          <div class="grid width-boxed">
            <div class="col-12 md:col-6 lg:col-6 tabby-plan-box-right">
              <h2 class="inner-heading-title inner-heading-title-tabby">Insure Now Pay Later <br> With <img
                  src="../assets/images/tabby-icon.svg">
              </h2>
              <p class="inner-para-text">Secure your coverage with MECompare and enjoy the convenience of Tabby's
                flexible payment options. Choose from:</p>

              <div class="grid w-full">
                <div class="col-12 md:col-6 lg:col-6 relative tabby-plan-box-pl">
                  <div class="section-gradient section-gradient-plan-box">
                    <h4 class="info-box-title">2 Month Plan</h4>
                    <p class="info-box-description">Play in 2 months at your flexibility</p>
                  </div>
                </div>

                <div class="col-12 md:col-6 lg:col-6 relative tabby-plan-box-pr">
                  <div class="section-gradient section-gradient-plan-box">
                    <h4 class="info-box-title">4 Month Plan</h4>
                    <p class="info-box-description">Play in 4 months at your flexibility</p>
                  </div>
                </div>
              </div>

            </div>

            <div class="col-12 md:col-6 lg:col-6 relative">
              <div class="inner-section-image">
                <img src="../assets/images/tabby-mobile.png">
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-padding our-loyal-customer">
        <div class="container">
          <div class="section-title-heading">
            <h2 class="mb-3">Keeping the trust of our loyal <br> customer</h2>
          </div>

          <div class="why-choose-inner-area width-boxed">
            <div class="grid w-full">
              <div class="col-12 md:col-6 lg:col-6">
                <div class="info-box-column">
                  <div class="icon-box-area">
                    <img src="../assets/images/Icons/user-testimonial.svg">
                  </div>

                  <p class="info-box-description">Exceptional service! MECompare made getting my car insurance a
                    breeze. Their online platform was easy to use.</p>
                  <h4 class="info-box-title">Sara R.</h4>
                </div>
              </div>

              <div class="col-12 md:col-6 lg:col-6">
                <div class="info-box-column">
                  <div class="icon-box-area">
                    <img src="../assets/images/Icons/user-testimonial.svg">
                  </div>

                  <p class="info-box-description">Choosing MECompare was the best decision I made for my car
                    insurance. The claims process was smooth.
                  </p>
                  <h4 class="info-box-title">John K</h4>
                </div>
              </div>

              <div class="col-12 md:col-6 lg:col-6">
                <div class="info-box-column">
                  <div class="icon-box-area">
                    <img src="../assets/images/Icons/user-testimonial.svg">
                  </div>

                  <p class="info-box-description">I've been a MECompare customer for two years now, and their
                    service has been consistently excellent. </p>
                  <h4 class="info-box-title">Maleha Khan.</h4>
                </div>
              </div>

              <div class="col-12 md:col-6 lg:col-6">
                <div class="info-box-column">
                  <div class="icon-box-area">
                    <img src="../assets/images/Icons/user-testimonial.svg">
                  </div>

                  <p class="info-box-description">MECompare offers more than just coverage; they offer peace of
                    mind. The claims team was responsive and efficient.</p>
                  <h4 class="info-box-title">Mona Sameer</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-padding faq-accordion-section">
        <div class="container">
          <div class="section-title-heading">
            <h2 class="mb-3 flex align-items-center justify-content-center">FAQ</h2>
          </div>

          <div class="faq-accordion-card width-boxed">
            <accordion-prime :activeIndex="0">
              <accordionTab-prime header="How do I get a quote for insurance products offered by MECompare Insurance?">
                <p class="m-0">
                  You can easily obtain a quote by providing details about your vehicle and personal information through
                  our online quote tool on the Lime Insurance website.
                </p>
              </accordionTab-prime>
              <accordionTab-prime header="What sets MECompare Insurance apart from other insurance providers?">
                <p class="m-0">
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                  totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta
                  sunt explicabo. Nemo enim
                  ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
                  eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius
                  modi.
                </p>
              </accordionTab-prime>
              <accordionTab-prime header="Can I customize my insurance coverage with MECompare Insurance?">
                <p class="m-0">
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
                  deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non
                  provident, similique sunt in culpa qui
                  officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et
                  expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo
                  minus.
                </p>
              </accordionTab-prime>
              <accordionTab-prime header="How can I file a claim with MECompare Insurance?">
                <p class="m-0">
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
                  deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non
                  provident, similique sunt in culpa qui
                  officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et
                  expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo
                  minus.
                </p>
              </accordionTab-prime>
              <accordionTab-prime
                header="Does MECompare Insurance offer any additional services or benefits beyond basic coverage?">
                <p class="m-0">
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
                  deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non
                  provident, similique sunt in culpa qui
                  officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et
                  expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo
                  minus.
                </p>
              </accordionTab-prime>
            </accordion-prime>
          </div>
        </div>
      </section>
    </div>
  </main>

  <FooterView />

  <!-- OTP Modal Html Start -->
  <dialog-prime class="otp-modal" v-model:visible="visibleOtpModal" modal header="Otp" :style="{ width: '36rem' }">

    <div class="modal-content-otp">
      <h3>Enter your OTP</h3>
      <span class="p-text-secondary block mb-5">To proceed with your request, please enter the OTP (One-Time Password)
        sent to your registered mobile number.</span>
      <InputOtp-prime v-model="valueotp" :length="6" />

      <div class="flex justify-content-center gap-2 mt-5">
        <button-prime type="button" label="Resend OTP" severity="secondary" class="resend-otp-btn"></button-prime>
        <button-prime type="button" label="Submit" class="submit-otp-btn" @click="visible = false"></button-prime>
      </div>

      <div class="flex justify-content-center gap-2 mb-5">
        <button-prime type="button" label="Cancel" severity="secondary" class="cancel-otp-popupbtn"
          @click="visible = false"></button-prime>
      </div>

      <span class="p-text-secondary block">The OTP is valid for a limited time. Ensure you enter it promptly to
        avoid
        expiration. If you haven't received the OTP within a few minutes, you can click "Resend OTP" to receive a new
        one.</span>
    </div>
  </dialog-prime>
  <!-- / OTP Modal Html End -->

</template>

<script>
import HeaderView from '../components/Header/HeaderView.vue'
import FooterView from '../components/Footer/FooterView.vue'

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
    components: {
      HeaderView,
      FooterView,
      Swiper,
      SwiperSlide,
    },
    data() {
      return {
        finishMounted: false,
        default_country : 'AE',
        phone_number : localStorage.getItem("mebrokers.customer.phone.number") == null ? null : localStorage.getItem("mebrokers.customer.phone.number"),
        is_phone_number_invalid : false,
        invalid_phone_number_message : 'Invalid Phone Number',
        show_invalid_phone_number_message : false,
        modules: [Navigation, Pagination, Autoplay, Scrollbar, A11y],
        pagination: {
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + '</span>';
          },
        },
        inputOptions : { 
          // dynamicPlaceholder : true,
          showDialCode : true,
          placeholder : 'Enter Phone Number'
          
        },
        dropdownOptions : {
          showDialCodeInList : true,
          showFlags : true,
          showSearchBox : true,
          
        }
      }
        
    },
    methods : {
      onInput : function(phone, obj) {
        if(obj.valid) {
          this.is_phone_number_invalid = false;
        } else {
          this.is_phone_number_invalid = true;
        }
      },
      validate_phone_number : function() {
        if(!this.is_phone_number_invalid) {
          this.show_invalid_phone_number_message = false;
          localStorage.setItem("mebrokers.customer.phone.number", this.phone_number.replaceAll(' ', ''));
          localStorage.removeItem('mebrokers.customer.motor.journey');
          localStorage.removeItem('mebrokers.customer.motor.plans.comparison');
          this.$router.push({
              name : 'motor-journey',
          });
        } else {
          this.show_invalid_phone_number_message = true;
          return false;
        }
      },
      valid : function(obj) {
        if (obj.valid != undefined) {
          if(obj.valid) {
            this.show_invalid_phone_number_message = false;
          }
        }
      },
      
    }
}
</script>

<script setup>
import { ref } from "vue";

const visibleOtpModal = ref(false);
const valueotp = ref(null);
const valuerating = ref(null);

</script>