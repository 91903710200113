<template>
    <HeaderJourney />

    <div class="main-upload-document-page">
        <div class="container">
            <div class="grid ml-0 mr-0 box-width-sm">
                <div class="col-12 md:col-12 lg:col-12">
                    <div class="progress-bar-container">
                        <div class="progress-head">
                            <router-link to="/">
                                <p class="progress-bar-label back-btn-flow">
                                    <i class="pi pi-home"></i>
                                    Home
                                </p>
                            </router-link>
                        </div>
                        <progressBar-prime :value="100"></progressBar-prime>
                    </div>
                </div>
            </div>

            <div class="grid ml-0 mr-0 box-width-sm">
                <div class="col-12 md:col-12 lg:col-12">
                    <div class="purchase-complete-icon">
                        <img src="../../assets/images/Icons/check-green.svg">
                    </div>
                    <h2 class="upload-document-title mb-1 mt-0">Request Completed!</h2>
                    <p class="puchase-complete-para">
                        Thank you for selecting {{ company_name }} as your insurance provider. You will receive an email shortly
                        with
                        your policy details. In case you did not receive any email you can contact our customer support.

                        <br>
                        <a href="#">{{ company_email }}</a>

                        <br>
                        <a href="#">{{ company_phone_number }}</a>
                    </p>

                    <h2 class="upload-document-title mb-0">{{ ('quotation_number' in quotation) ? quotation.quotation_number : '-'}}</h2>
                    <p class="puchase-complete-para">
                        Quote Reference Number
                    </p>

                    <div class="puchase-complete-info-box">
                        <h4>Plan Purchased Summary</h4>

                        <div class="info-flex-colunm">
                            <p class="col-width-50">
                                Policy type
                            </p>

                            <p class="col-width-50">
                                Medical Insurance
                            </p>
                        </div>

                        <div class="info-flex-colunm">
                            <p class="col-width-50">
                                Coverage Type
                            </p>

                            <p class="col-width-50">
                                {{ ('coverage_type' in quotation) ? quotation.coverage_type.name : '-'}}
                            </p>
                        </div>

                        

                       
                    </div>
                    <div class="puchase-complete-info-box">
                            <h4>Insured Details</h4>
                            <template v-if="('insured_peoples' in quotation)">
                                <template v-for="(people, index) in quotation.insured_peoples" :key="index"> 
                                    <h4 v-if="quotation.insured_peoples.length>1">Family Member {{ (parseInt(index)+1) }}</h4>
                                        <div class="info-flex-colunm">
                                            <p class="col-width-50">
                                                Full Name
                                            </p>

                                            <p class="col-width-50">
                                                {{ people.full_name }}
                                            </p>
                                        </div>

                        
                                        <div class="info-flex-colunm">
                                            <p class="col-width-50">    
                                                Birth Date
                                            </p>

                                            <p class="col-width-50">
                                                {{ people.date_of_birth }}
                                            </p>
                                        </div>
                                    </template>
                                </template>
                                </div>
                        </div>
                </div>

            <div class="grid ml-0 mr-0 box-width-sm">
                <div class="col-12 md:col-12 lg:col-12">
                    <h2 class="upload-document-title mb-4">What's Next?</h2>

                    <div class="puchase-complete-info-box">
                        <h4>Policy Confirmation</h4>

                        <div class="info-flex-colunm">
                            <p class="col-width-100">
                                A detailed confirmation of your insurance policy will be sent to your email shortly.
                                Keep it handy for future reference.
                            </p>
                        </div>
                    </div>

                    <div class="puchase-complete-info-box">
                        <h4>Need Assistance?</h4>

                        <div class="info-flex-colunm">
                            <p class="col-width-100">
                                Our dedicated support team is here to assist your matters. Feel free to contact us for
                                any inquiries.
                            </p>
                        </div>

                        <div class="button-flex-box">
                            <button-prime class="btn-assistance chat" icon="pi pi-whatsapp" label="Chat Now" />
                            <button-prime class="btn-assistance contact" icon="pi pi-phone" :label="company_phone_number" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderJourney from '../Header/HeaderJourney.vue'
import axios from 'axios'
import moment from 'moment'
export default {
    data() {
        return {
            quotation : {}
        }
    },
    components: {
        HeaderJourney
    },
    mounted() {
        this.get_quotation_details();
    },
    methods : {
        get_quotation_details : function() {
            axios.get(this.api_url+'medical/get_quotation_details', {
                params : {
                    quotation_id : this.$route.params.quotation_id
                }
            }).then((response) => {
                this.quotation = response.data.data.quotation;
                for (let i = 0; i < this.quotation.insured_peoples.length; i++) {
                    this.quotation.insured_peoples[i].date_of_birth = moment(this.quotation.insured_peoples[i].date_of_birth).format("DD MMMM YYYY");
                }
            });
        }
        
    }
}
</script>

<style>

</style>