<template>
    <HeaderJourney />

    <div class="main-listing-page">
        <div class="container">
            <div class="grid ml-0 mr-0">
                <div class="col-12 md:col-12 lg:col-12">
                    <div class="flow-option-back flow-option-back-listing">
                        <ul class="flow-option-back-ul">
                            <li v-if="$route.query.is_from == undefined" class="flow-option-back-li"
                                @click="go_back_to_motor_journey_page('user_detail')">
                                <i class="pi pi-arrow-left"></i>
                                Back
                            </li>
                            <li class="inquiry-number">
                                <span>Inquiry Number:</span> <span>{{ ('quotation_number' in quotation) ?
                                    quotation.quotation_number : '-'}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="grid ml-0 mr-0 flow-info-editable-box" v-if="$route.query.is_from == undefined">
                <div class="col-12 md:col-12 lg:col-12">
                    <swiper :slides-per-view="7" :space-between="0" setWrapperSize="ture" :navigation="true" :loop="true"
                    :pagination="pagination" :modules="modules" :breakpoints="{
                            '@0.00': { slidesPerView: 1, spaceBetween: 0 },
                            '@0.75': { slidesPerView: 2, spaceBetween: 0 },
                            '@1.00': { slidesPerView: 5, spaceBetween: 0 },
                            '@1.50': { slidesPerView: 7, spaceBetween: 0 },
                    }" @swiper="onSwiper" @slideChange="onSlideChange">
                    <swiper-slide>
                        <div class="flow-option-editable">
                            <div class="flow-option-list">
                                <ul class="flow-option-list-ul">
                                    <li class="flow-option-list-li"
                                        v-bind:class="(step == 'flow-one') ? 'flow-active' : ''">
                                        <div class="flow-info">
                                            <div class="flow-number-badge">
                                                <span>1</span>
                                            </div>
                                            <div class="flow-type">
                                                <p class="flow-type-text">Vehicle Brand</p>
                                                <h4 class="flow-type-title">{{ ('make' in quotation) ? quotation.make.name :
                                                    '-'}}</h4>
                                            </div>
                                        </div>
                                        <div class="flow-action">
                                            <div @click="go_back_to_motor_journey_page('make')" class="flow-edit-box">
                                                <span><i class="pi pi-pencil"></i></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="flow-option-editable">
                            <div class="flow-option-list">
                                <ul class="flow-option-list-ul">
                                    <li class="flow-option-list-li"
                                        v-bind:class="(step == 'flow-two') ? 'flow-active' : ''">
                                        <div class="flow-info">
                                            <div class="flow-number-badge">
                                                <span>2</span>
                                            </div>
                                            <div class="flow-type">
                                                <p class="flow-type-text">Vehicle Model</p>
                                                <h4 class="flow-type-title">{{ ('model' in quotation) ? quotation.model.name
                                                    : '-'}}</h4>
                                            </div>
                                        </div>
                                        <div class="flow-action">
                                            <div @click="go_back_to_motor_journey_page('model')" class="flow-edit-box">
                                                <span><i class="pi pi-pencil"></i></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="flow-option-editable">
                            <div class="flow-option-list">
                                <ul class="flow-option-list-ul">
                                    <li class="flow-option-list-li"
                                        v-bind:class="(step == 'flow-three') ? 'flow-active' : ''">
                                        <div class="flow-info">
                                            <div class="flow-number-badge">
                                                <span>3</span>
                                            </div>
                                            <div class="flow-type">
                                                <p class="flow-type-text">Model Year</p>
                                                <h4 class="flow-type-title">{{ ('year' in quotation) ? quotation.year.name :
                                                    '-'}}</h4>
                                            </div>
                                        </div>
                                        <div class="flow-action">
                                            <div @click="go_back_to_motor_journey_page('year')" class="flow-edit-box">
                                                <span><i class="pi pi-pencil"></i></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="flow-option-editable">
                            <div class="flow-option-list">
                                <ul class="flow-option-list-ul">
                                    <li class="flow-option-list-li specification-info-box"
                                        v-bind:class="(step == 'flow-three') ? 'flow-active' : ''">
                                        <div class="flow-info">
                                            <div class="flow-number-badge">
                                                <span>3</span>
                                            </div>
                                            <div class="flow-type">
                                                <p class="flow-type-text">Specification</p>
                                                <h4 class="flow-type-title">{{ ('specification' in quotation) ?
                                                    quotation.specification.name : '-'}}</h4>
                                            </div>
                                        </div>
                                        <div class="flow-action">
                                            <div @click="go_back_to_motor_journey_page('specification')"
                                                class="flow-edit-box">
                                                <span><i class="pi pi-pencil"></i></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="flow-option-editable">
                            <div class="flow-option-list">
                                <ul class="flow-option-list-ul">
                                    <li class="flow-option-list-li"
                                        v-bind:class="(step == 'flow-four') ? 'flow-active' : ''">
                                        <div class="flow-info">
                                            <div class="flow-number-badge">
                                                <span>4</span>
                                            </div>
                                            <div class="flow-type">
                                                <p class="flow-type-text">City</p>
                                                <h4 class="flow-type-title">{{ ('city' in quotation) ? quotation.city.name :
                                                    '-'}}</h4>
                                            </div>
                                        </div>
                                        <div class="flow-action">
                                            <div @click="go_back_to_motor_journey_page('city')" class="flow-edit-box">
                                                <span><i class="pi pi-pencil"></i></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="flow-option-editable">
                            <div class="flow-option-list">
                                <ul class="flow-option-list-ul">
                                    <li class="flow-option-list-li"
                                        v-bind:class="(step == 'flow-five') ? 'flow-active' : ''">
                                        <div class="flow-info">
                                            <div class="flow-number-badge">
                                                <span>5</span>
                                            </div>
                                            <div class="flow-type">
                                                <p class="flow-type-text">Insurance Type</p>
                                                <h4 class="flow-type-title" v-if="('insurance_type' in quotation)">{{
                                                    quotation.insurance_type.name }}</h4>
                                            </div>
                                        </div>
                                        <div class="flow-action">
                                            <div @click="go_back_to_motor_journey_page('insurance_type')"
                                                class="flow-edit-box">
                                                <span><i class="pi pi-pencil"></i></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="flow-option-editable">
                            <div class="flow-option-list">
                                <ul class="flow-option-list-ul">
                                    <li class="flow-option-list-li"
                                        v-bind:class="(step == 'flow-six') ? 'flow-active' : ''">
                                        <div class="flow-info">
                                            <div class="flow-number-badge">
                                                <span>6</span>
                                            </div>
                                            <div class="flow-type">
                                                <p class="flow-type-text">Insurance Start Date</p>
                                                <h4 class="flow-type-title">{{ ('start_date' in quotation) ?
                                                    moment(quotation.start_date).format("DD MMMM YYYY") : '-' }}</h4>
                                            </div>
                                        </div>
                                        <div class="flow-action">
                                            <div @click="go_back_to_motor_journey_page('insurance_start_date')"
                                                class="flow-edit-box">
                                                <span><i class="pi pi-pencil"></i></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </swiper-slide>
                    </swiper>
                </div>
            </div>

            <div class="grid ml-0 mr-0 mb-4">
                <div class="col-12 md:col-4 lg:col-3 listing-filter-area">
                    <div class="left-filters-area">
                        <div class="filter-options-area">
                            <div class="filter-option-list mt-0">
                                <h4>Car Value</h4>

                                <div class="flex flex-column gap-2">
                                    <div class="flex align-items-center">
                                        <radioButton-prime v-model="filter1" inputId="filter1" name="one" value="one" />
                                        <label for="filter1" class="ml-2">Recommended</label>
                                    </div>
                                    <div class="flex align-items-center">
                                        <radioButton-prime v-model="filter1" inputId="filter2" name="two" value="two" />
                                        <label for="filter2" class="ml-2">Set your vehicle value</label>
                                    </div>
                                </div>

                                <div class="anual-price-range">
                                    <slider-prime @slideend="calculate_premium()"
                                        v-model="filters.selected_vehicle_value" :max="vehicle_value_range.max"
                                        :min="vehicle_value_range.min" class="w-full" :formatter="100" />
                                    <div class="anual-price-text">
                                        <span>Vehicle Value: {{ filters.selected_vehicle_value }}</span>
                                    </div>
                                    <div class="anual-price-text">
                                        <span>Enter the amount of vehicle between AED {{ vehicle_value_range.min }} to
                                            AED {{vehicle_value_range.max}}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="filter-option-list">
                                <h4>Insurance Plan</h4>
                                <div class="flex flex-column gap-2">
                                    <div class="flex align-items-center">
                                        <radioButton-prime @change="calculate_premium()"
                                            v-model="filters.selected_insurance_type_id" :inputId="'insurance_type_0'"
                                            name="insurance_type" :value="0" />
                                        <label for="insurance_type_0" class="ml-2">All Insurance types</label>
                                    </div>
                                    <div class="flex align-items-center" v-for="item in filters.insurance_types"
                                        :key="item">
                                        <radioButton-prime @change="calculate_premium()"
                                            v-model="filters.selected_insurance_type_id"
                                            :inputId="'insurance_type_'+item.id" name="insurance_type"
                                            :value="item.id" />
                                        <label :for="'insurance_type_'+item.id" class="ml-2">{{item.name}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="filter-option-list">
                                <h4>Repairing Type</h4>
                                <div class="flex flex-column gap-2">
                                    <div class="flex align-items-center">
                                        <radioButton-prime @change="calculate_premium()"
                                            v-model="filters.selected_repair_type_id" :inputId="'repair_type_0'"
                                            name="repair_type" :value="0" />
                                        <label for="repair_type_0" class="ml-2">All Repair Types</label>
                                    </div>
                                    <div class="flex align-items-center" v-for="item in filters.repair_types"
                                        :key="item">
                                        <radioButton-prime @change="calculate_premium()"
                                            v-model="filters.selected_repair_type_id" :inputId="'repair_type_'+item.id"
                                            name="repair_type" :value="item.id" />
                                        <label :for="'repair_type_'+item.id" class="ml-2">{{item.name}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="filter-option-list">
                                <h4>Insurance Type</h4>
                                <div class="flex flex-column gap-2">
                                    <div class="flex align-items-center">
                                        <radioButton-prime v-model="filter4" inputId="filter9" name="nine"
                                            value="nine" />
                                        <label for="filter9" class="ml-2">All types</label>
                                    </div>
                                    <div class="flex align-items-center">
                                        <radioButton-prime v-model="filter4" inputId="filter10" name="ten"
                                            value="ten" />
                                        <label for="filter10" class="ml-2">Takaful</label>
                                    </div>
                                    <div class="flex align-items-center">
                                        <radioButton-prime v-model="filter4" inputId="filter11" name="eleven"
                                            value="eleven" />
                                        <label for="filter11" class="ml-2">Non-Takaful</label>
                                    </div>
                                </div>
                            </div>

                            <div class="filter-option-list">
                                <h4>Insurance Companies</h4>
                                <div class="flex flex-column gap-2">
                                    <div class="flex align-items-center">
                                        <radioButton-prime @change="calculate_premium()"
                                            v-model="filters.selected_company_id" :inputId="'company_0'" name="company"
                                            :value="0" />
                                        <label for="company_0" class="ml-2">All Comapnies</label>
                                    </div>
                                    <div class="flex align-items-center" v-for="item in filters.companies" :key="item">
                                        <radioButton-prime @change="calculate_premium()"
                                            v-model="filters.selected_company_id" :inputId="'company_'+item.id"
                                            name="company" :value="item.id" />
                                        <label :for="'company_'+item.id" class="ml-2">{{item.name}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="filter-option-list border-0 pb-0">
                                <h4>Car Specification</h4>

                                <div class="flex flex-column gap-2 check-box-filters">
                                    <div class="flex align-items-center">
                                        <checkbox-prime v-model="filter6" inputId="filter17" name="seventen"
                                            value="seventen" />
                                        <label for="filter17" class="ml-2">Recommended</label>
                                    </div>
                                    <div class="flex align-items-center">
                                        <checkbox-prime v-model="filter6" inputId="filter18" name="eighten"
                                            value="eighten" />
                                        <label for="filter18" class="ml-2">Set your vehicle value</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-8 lg:col-9">
                    <div class="right-listing-items-area">
                        <div class="listing-tabs-container">
                            <button-prime class="filters p-0 listing-filter-btn-mobile" aria-label="filters"
                                @click="visible = true">
                                <span class=" px-3">Filter</span>
                                <img src="../../assets/images/sort.svg">
                            </button-prime>

                            <div class="listing-tab-panel">
                                <template v-for="item in filters.insurance_types" :key="item">
                                    <div class="listing-tab-btn"
                                        v-bind:class="filters.selected_insurance_type_id==item.id ? 'active' : ''"
                                        @click=filter_insurance_type(item.id)>
                                        {{ item.name }}({{ item.id == 1 ? comprehensive_plans_count : tpl_plans_count
                                        }})
                                    </div>
                                </template>
                            </div>

                            <div class="listing-sort-container">
                                <dropdown-prime @change="calculate_premium()" v-model="filters.selected_sort"
                                    :options="filters.sort" optionLabel="name" placeholder="Sort By" class="w-full" />
                            </div>
                        </div>

                        <div class="tabs-listing-content">
                            <!-- Motor listing -->
                            <div class="tabs-listing-box" v-for="(plan, index) in plans" :key="index">
                                <div class="listing-top-header">
                                    <div class="logo-box-container">
                                        <div class="logo-box">
                                            <img v-if="plan.company_logo != null" :src="plan.company_logo">
                                            <img v-else src="../../assets/images/logo-img.png">
                                        </div>
                                        <div class="info-listing-text">
                                            <p class="company-name-text">{{ plan.company_name }}</p>
                                            <p class="plan-name-text">{{ plan.plan_name }}</p>
                                        </div>
                                    </div>
                                    <div class="header-info-right">
                                        <div class="currency-info">
                                            <div class="annual-premium">
                                                <span class="regular-text">Annual Premium</span>
                                                <span class="bold-text">AED {{ plan.gross_premium }}</span>
                                            </div>
                                            <div class="vat">
                                                <span class="regular-text">VAT (5%)</span>
                                                <span class="bold-text">AED {{ plan.tax_amount }}</span>
                                            </div>
                                        </div>
                                        <div class="btn-right-area">
                                            <small>Pay in 4 installments at AED 34.09/month</small>
                                            <button-prime label="Buy Now" class="buy-now-btn"
                                                :loading="loading_buy_now_button"
                                                @click="update_quotation_details(plan)" />
                                            <div class="footer-download-send-btn">
                                                <button-prime
                                                    @click="generate_pdf(plan.company_id, plan.plan_id, plan.insurance_type_id, plan.repair_type_id)"
                                                    icon="pi pi-download" severity="secondary" outlined />
                                                <button-prime icon="pi pi-send" severity="secondary" outlined />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="listing-main-content">
                                    <div v-if="plan.excess!=''" class="middle-content-box">
                                        <div class="icon-area">
                                            <img src="../../assets/images/Icons/excess-icon.svg">
                                        </div>
                                        <div class="info-area">
                                            <h4>Excess</h4>
                                            <p>AED {{ plan.excess }}</p>
                                        </div>
                                    </div>
                                    <div class="middle-content-box" v-if="plan.repair_type_id!=0">
                                        <div class="icon-area">
                                            <img src="../../assets/images/Icons/repairs-type.svg">
                                        </div>
                                        <div class="info-area">
                                            <h4>Repair Types</h4>
                                            <p>{{ plan.repair_type_name }}</p>
                                        </div>
                                    </div>
                                    <div class="middle-content-box">
                                        <div class="icon-area">
                                            <img src="../../assets/images/Icons/car-value.svg">
                                        </div>
                                        <div class="info-area">
                                            <h4>Car Value</h4>
                                            <p>AED {{ filters.selected_vehicle_value }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="listing-footer">
                                    <div class="footer-likn-text">
                                        <router-link to="/terms-and-condition">
                                            Terms & Condition
                                        </router-link>
                                    </div>
                                    <div class="footer-collapse">
                                        <button-prime class="panel-open-btn" label="Explore Benefits and Ad-Ons"
                                            severity="secondary" outlined />
                                    </div>
                                    <div class="compare-box">
                                        <button-prime @click="open_compare_footer(plans[index])"
                                            class="p-0 transparent compare-box-btn" aria-label="compare">
                                            <span class="px-2">Add to Compare</span>
                                            <i class="pi pi-plus px-2"></i>
                                        </button-prime>
                                    </div>
                                </div>
                                <div class="listing-footerInfo">
                                    <accordion-prime v-model:activeIndex="active">
                                        <accordionTab-prime>
                                            <h2 class="listing-footerInfo-title">What's Included</h2>

                                            <div class="includes-info-list-container"
                                                v-if="plans[index].coverage_details.length>0">
                                                <template v-for="included_coverages in plans[index].coverage_details"
                                                    :key="included_coverages">
                                                    <div class="includes-info-list"
                                                        v-if="included_coverages.status=='included'">
                                                        <div class="includes-info-list-icon">
                                                            <img v-if="included_coverages.icon!=null"
                                                                :src="included_coverages.icon">
                                                        </div>
                                                        <h2 class="includes-info-title">{{ included_coverages.name }}{{
                                                            (included_coverages.value!=null &&
                                                            included_coverages.value!='') ? '-'+included_coverages.value
                                                            : '' }}
                                                        </h2>
                                                    </div>
                                                </template>
                                            </div>
                                            <div class="includes-info-list-container" v-else>
                                                <div class="includes-info-list">
                                                    <h2 class="includes-info-title">Coverages not found</h2>
                                                </div>
                                            </div>
                                            <h2 class="listing-footerInfo-title mt-5">Choose the add ons</h2>

                                            <div class="addons-info-list-container">
                                                <template v-for="optional_coverage in plans[index].coverage_details"
                                                    :key="optional_coverage">
                                                    <div class="addons-info-box"
                                                        v-if="optional_coverage.status=='optional'">
                                                        <div class="addons-info-area">
                                                            <div class="addons-header">
                                                                <div class="addons-icon">
                                                                    <img :src="optional_coverage.icon">
                                                                </div>
                                                                <h2 class="addons-title">{{ optional_coverage.name }}
                                                                </h2>
                                                            </div>
                                                            <p class="addons-description">
                                                                {{ optional_coverage.value != null &&
                                                                optional_coverage.value != '' ? optional_coverage.value
                                                                : '' }}
                                                            </p>
                                                        </div>
                                                        <div class="addons-price-add">
                                                            <div class="addons-prine-box">
                                                                <p class="m-0 addons-pricing-area">
                                                                    <span class="currency-label">AED</span>
                                                                    <span class="price-label">{{
                                                                        optional_coverage.amount }}</span>
                                                                </p>
                                                                <p class="m-0 per-year">per year</p>
                                                            </div>
                                                            <button-prime label="Add"
                                                                @click="add_coverage_in_premium(index, optional_coverage)"
                                                                :data-coverage-id="optional_coverage.id"
                                                                class="add-addons-btn addons-btn-add" />
                                                            <button-prime label="Remove"
                                                                @click="remove_coverage_in_premium(index, optional_coverage)"
                                                                :data-coverage-id="optional_coverage.id"
                                                                class="add-addons-btn addons-btn-remove d-hide" />
                                                        </div>
                                                    </div>
                                                </template>

                                            </div>
                                        </accordionTab-prime>
                                    </accordion-prime>
                                </div>
                            </div>
                            <!-- / Motor listing -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="compare-items-bottom" style="display:flex !important;" v-if="motor_compare.length>0">
            <div class="container">
                <div class="compare-box-inner">
                    <div class="compare-left">
                        <div class="compare-item" v-for="(compare, index1) in motor_compare" :key="index1">
                            <div class="compare-item-info">
                                <label>{{compare.company_name}}</label>
                                <h2>AED {{compare.premium}}</h2>
                            </div>
                            <div class="compare-item-delete" @click="remove_compare_index(index1)">
                                <i class="pi pi-times"></i>
                            </div>
                        </div>


                    </div>

                    <div class="compare-right">
                        <button-prime label="Compare All" @click="compare_all" class="compare-all-btn" />
                        <p class="clear-all" @click="clear_comparision_footer">Clear All</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <toast-prime />

    <!-- Filter Modal For Mobile -->
    <dialog-prime v-model:visible="visible" header="Filters" :style="{ width: '25rem' }" position="bottom" :modal="true"
        :draggable="false" class="listing-filter-modal-mobile">
        <div>
            <div class="grid ml-0 mr-0">
                <div class="col-12 md:col-12 lg:col-12 listing-filter-area-mobile">
                    <div class="left-filters-area">
                        <div class="filter-options-area">
                            <div class="filter-option-list mt-0">
                                <h4>Car Value</h4>

                                <div class="flex flex-column gap-2">
                                    <div class="flex align-items-center">
                                        <radioButton-prime v-model="filter1" inputId="filter1" name="one" value="one" />
                                        <label for="filter1" class="ml-2">Recommended</label>
                                    </div>
                                    <div class="flex align-items-center">
                                        <radioButton-prime v-model="filter1" inputId="filter2" name="two" value="two" />
                                        <label for="filter2" class="ml-2">Set your vehicle value</label>
                                    </div>
                                </div>

                                <div class="anual-price-range">
                                    <slider-prime @slideend="calculate_premium()"
                                        v-model="filters.selected_vehicle_value" :max="vehicle_value_range.max"
                                        :min="vehicle_value_range.min" class="w-full" :formatter="100" />
                                    <div class="anual-price-text">
                                        <span>Vehicle Value: {{ filters.selected_vehicle_value }}</span>
                                    </div>
                                    <div class="anual-price-text">
                                        <span>Enter the amount of vehicle between AED {{ vehicle_value_range.min }} to
                                            AED {{vehicle_value_range.max}}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="filter-option-list">
                                <h4>Insurance Plan</h4>
                                <div class="flex flex-column gap-2">
                                    <div class="flex align-items-center">
                                        <radioButton-prime @change="calculate_premium()"
                                            v-model="filters.selected_insurance_type_id" :inputId="'insurance_type_0'"
                                            name="insurance_type" :value="0" />
                                        <label for="insurance_type_0" class="ml-2">All Insurance types</label>
                                    </div>
                                    <div class="flex align-items-center" v-for="item in filters.insurance_types"
                                        :key="item">
                                        <radioButton-prime @change="calculate_premium()"
                                            v-model="filters.selected_insurance_type_id"
                                            :inputId="'insurance_type_'+item.id" name="insurance_type"
                                            :value="item.id" />
                                        <label :for="'insurance_type_'+item.id" class="ml-2">{{item.name}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="filter-option-list">
                                <h4>Repairing Type</h4>
                                <div class="flex flex-column gap-2">
                                    <div class="flex align-items-center">
                                        <radioButton-prime @change="calculate_premium()"
                                            v-model="filters.selected_repair_type_id" :inputId="'repair_type_0'"
                                            name="repair_type" :value="0" />
                                        <label for="repair_type_0" class="ml-2">All Repair Types</label>
                                    </div>
                                    <div class="flex align-items-center" v-for="item in filters.repair_types"
                                        :key="item">
                                        <radioButton-prime @change="calculate_premium()"
                                            v-model="filters.selected_repair_type_id" :inputId="'repair_type_'+item.id"
                                            name="repair_type" :value="item.id" />
                                        <label :for="'repair_type_'+item.id" class="ml-2">{{item.name}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="filter-option-list">
                                <h4>Insurance Type</h4>
                                <div class="flex flex-column gap-2">
                                    <div class="flex align-items-center">
                                        <radioButton-prime v-model="filter4" inputId="filter9" name="nine"
                                            value="nine" />
                                        <label for="filter9" class="ml-2">All types</label>
                                    </div>
                                    <div class="flex align-items-center">
                                        <radioButton-prime v-model="filter4" inputId="filter10" name="ten"
                                            value="ten" />
                                        <label for="filter10" class="ml-2">Takaful</label>
                                    </div>
                                    <div class="flex align-items-center">
                                        <radioButton-prime v-model="filter4" inputId="filter11" name="eleven"
                                            value="eleven" />
                                        <label for="filter11" class="ml-2">Non-Takaful</label>
                                    </div>
                                </div>
                            </div>

                            <div class="filter-option-list">
                                <h4>Insurance Companies</h4>
                                <div class="flex flex-column gap-2">
                                    <div class="flex align-items-center">
                                        <radioButton-prime @change="calculate_premium()"
                                            v-model="filters.selected_company_id" :inputId="'company_0'" name="company"
                                            :value="0" />
                                        <label for="company_0" class="ml-2">All Comapnies</label>
                                    </div>
                                    <div class="flex align-items-center" v-for="item in filters.companies" :key="item">
                                        <radioButton-prime @change="calculate_premium()"
                                            v-model="filters.selected_company_id" :inputId="'company_'+item.id"
                                            name="company" :value="item.id" />
                                        <label :for="'company_'+item.id" class="ml-2">{{item.name}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="filter-option-list border-0 pb-0">
                                <h4>Car Specification</h4>

                                <div class="flex flex-column gap-2 check-box-filters">
                                    <div class="flex align-items-center">
                                        <checkbox-prime v-model="filter6" inputId="filter17" name="seventen"
                                            value="seventen" />
                                        <label for="filter17" class="ml-2">Recommended</label>
                                    </div>
                                    <div class="flex align-items-center">
                                        <checkbox-prime v-model="filter6" inputId="filter18" name="eighten"
                                            value="eighten" />
                                        <label for="filter18" class="ml-2">Set your vehicle value</label>
                                    </div>
                                </div>
                            </div>

                            <div class="filter-btn-area">
                                <button-prime label="Apply filters" class="apply-filter-btn" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </dialog-prime>
    <!-- / Filter Modal For Mobile -->
</template>

<script>
import HeaderJourney from '../Header/HeaderJourney.vue'
import axios from 'axios'
import $ from 'jquery'
import moment from 'moment'

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
    data() {
        return {
            modules: [
                Navigation, Pagination, Autoplay, Scrollbar
            ],
            quotation : {
                vehicle_value : 5000,
                insurance_type_id : 0
            },
            plans : [],
            comprehensive_plans_count : 0,
            tpl_plans_count : 0,
            
            vehicle_value_range : {
                min : 5000,
                max : 500000
            },
            filters : {
                insurance_types : [],
                companies : [],
                repair_types : [],
                sort : [{ name: 'High to Low Premium', id: 'high-to-low-premium' },{ name: 'Low to High Premium', id: 'low-to-high-premium' }],
                selected_sort : null,
                selected_company_id : 0,
                selected_repair_type_id : 0,
                selected_insurance_type_id : 0,
                selected_vehicle_value : 0
            },
            motor_compare : [],
            loading_buy_now_button : false
        }
    },
    components: {
        HeaderJourney,
        Swiper,
        SwiperSlide,
    },
    mounted() {
        this.get_quotation_details();
        this.get_filters_data();
        localStorage.removeItem('mebrokers.customer.motor.plans.comparison');
        $(document).ready(function () {
            $(document).on('click', '.panel-open-btn', function () {
                if ($(this).parents('.tabs-listing-box').hasClass('accordion-open')) {
                    $(this).parents('.tabs-listing-box').removeClass('accordion-open')
                } else {
                    $('.tabs-listing-box').removeClass('accordion-open')
                    $(this).parents('.tabs-listing-box').addClass('accordion-open')
                }
            })

            // $(document).on('click', '.compare-box-btn', function () {
            //     $('.compare-items-bottom').css('display', 'flex');
            // })

            // $(document).on('click', '.clear-all', function () {
            //     $('.compare-items-bottom').css('display', '');
            // })
        })
    },
    methods: {
        go_back_to_motor_journey_page : function(step) {
            if (localStorage.getItem('mebrokers.customer.motor.journey') != null) {
                var parse = JSON.parse(localStorage.getItem('mebrokers.customer.motor.journey'));
                parse.step = step;
                localStorage.setItem('mebrokers.customer.motor.journey', JSON.stringify(parse))
            }
            this.$router.push({
                name : 'motor-journey',
            });
        },
        get_quotation_details : function() {
            axios.get(this.api_url+'motor/get_quotation_details', {
                params : {
                    quotation_id : this.$route.params.quotation_id
                }
            }).then((response) => {
                this.quotation = response.data.data.quotation;
                this.filters.selected_insurance_type_id = this.quotation.insurance_type_id;
                this.filters.selected_vehicle_value  = this.quotation.vehicle_value
                this.calculate_premium();
            });
        },
        calculate_premium : function() {
            axios.get(this.api_url+'motor/calculate_premium', {
                params : {
                    quotation_id : this.$route.params.quotation_id,
                    filters : {
                        sort_by : this.filters.selected_sort != null ? this.filters.selected_sort.id : null,
                        company : this.filters.selected_company_id,
                        repair_type : this.filters.selected_repair_type_id,
                        insurance_type : this.filters.selected_insurance_type_id,
                        vehicle_value : this.filters.selected_vehicle_value
                    }
                }
            }).then((response) => {
                this.plans = response.data.data.plans;
                this.comprehensive_plans_count = response.data.data.comprehensive_plans_count;
                this.tpl_plans_count = response.data.data.tpl_plans_count;
            });
        },
        add_coverage_in_premium : function(plan_index, coverage_data) {
            this.plans[plan_index].added_coverages.push({
                coverage_id : coverage_data.id,
                coverage_name : coverage_data.name,
                coverage_arabic_name : coverage_data.arabic_name,
                coverage_value : coverage_data.value,
                coverage_amount : coverage_data.amount
            });
            this.plans[plan_index].gross_premium = parseFloat(this.plans[plan_index].gross_premium)+parseFloat(coverage_data.amount); 
            $('.addons-btn-add[data-coverage-id="'+coverage_data.id+'"]').addClass('d-hide');
            $('.addons-btn-remove[data-coverage-id="'+coverage_data.id+'"]').removeClass('d-hide');
        },
        remove_coverage_in_premium : function(plan_index, coverage_data) {
            var index_for_delete  = 0;
            for (let i = 0; i < this.plans[plan_index].added_coverages.length; i++) {
                if (this.plans[plan_index].added_coverages[i].coverage_id == coverage_data.id) {
                    index_for_delete = i;                   
                }
            }
            this.plans[plan_index].added_coverages.splice(index_for_delete, 1);  
            this.plans[plan_index].gross_premium = parseFloat(this.plans[plan_index].gross_premium)-parseFloat(coverage_data.amount); 
            $('.addons-btn-add[data-coverage-id="'+coverage_data.id+'"]').removeClass('d-hide');
            $('.addons-btn-remove[data-coverage-id="'+coverage_data.id+'"]').addClass('d-hide');
        },
        get_filters_data : function() {
            axios.get(this.api_url+'motor/get_filters_data').then((response) => {
                this.filters.insurance_types = response.data.data.insurance_types;
                this.filters.companies = response.data.data.companies;
                this.filters.repair_types = response.data.data.repair_types;
            });
        },
        generate_pdf : function (company_id, plan_id, insurance_type_id, repair_type_id) {
            window.open(this.api_url+'motor/download-company-plan-quotation/'+this.$route.params.quotation_id+'/'+company_id+'/'+plan_id+'/'+insurance_type_id+'/'+repair_type_id+'/'+this.filters.selected_vehicle_value, '_blank');
        },
        filter_insurance_type : function (id) {
            this.filters.selected_insurance_type_id = id;
            this.calculate_premium();
        },
        open_compare_footer(data) {
            if(this.motor_compare.length < 3){
                var validation_for_same = true;
                for (var key in this.motor_compare) {
                    if ((this.motor_compare[key].plan_id == data.plan_id) && (this.motor_compare[key].company_id == data.company_id) && (this.motor_compare[key].insurance_type_id == data.insurance_type_id)) {
                        validation_for_same = false;
                        break;
                    }
                }
                if (!validation_for_same) {
                    this.$toast.add({ severity: 'error', detail: 'This plan is alread selected!', life: 3000 });
                    return false;
                }
            }

            if(this.motor_compare.length == 3) {
                this.$toast.add({ severity: 'error', detail: 'Maximum range for comparison is only 3!', life: 3000 });
                return false;
            } else {
                this.motor_compare.push({
                    company_id : data.company_id,
                    plan_id : data.plan_id,
                    insurance_type_id : data.insurance_type_id,
                    repair_type_id : data.repair_type_id,
                    company_name : data.company_name,
                    premium : data.premium,
                    vehicle_value : this.filters.selected_vehicle_value
                });
            }
        },
        clear_comparision_footer() {
            this.motor_compare = [];
            localStorage.removeItem('mebrokers.customer.motor.plans.comparison');
        },
        remove_compare_index : function(ind) {
            this.motor_compare.splice(ind, 1); 
        },
        compare_all : function() {
            localStorage.removeItem('mebrokers.customer.motor.plans.comparison');
            localStorage.setItem('mebrokers.customer.motor.plans.comparison', JSON.stringify(this.motor_compare));
            this.$router.push({
                path : '/motor/comparison-sheet/'+this.$route.params.quotation_id,
            });
        },
        update_quotation_details : function(data) {
            this.loading_buy_now_button = true;
            axios.get(this.api_url+'motor/update_quotation_details',{
                params : {
                    quotation_id : this.$route.params.quotation_id,
                    vehicle_value : this.filters.selected_vehicle_value,
                    company_id : data.company_id,
                    plan_id : data.plan_id,
                    insurance_type_id : data.insurance_type_id,
                    additional_coverages : data.added_coverages,
                    premium : data.premium,
                    excess : data.excess,
                    start_date : this.quotation.start_date,
                    status : 'missing-documents'
                }
            }).then((response) => {
                this.loading_buy_now_button = false;
                if (response.data.data == true) {
                    if (this.$route.query.is_from != undefined && this.$route.query.is_from == 'admin') {
                        this.$toast.add({ severity: 'success', detail: 'Changes saved!', life: 3000 });
                    } else {
                        this.$router.push({
                            path : '/motor/upload-documents/'+this.$route.params.quotation_id,
                        });
                    }
                    
                }
            });
        }
    }
}
</script>

<script setup>
import { ref } from 'vue';
const active = ref(1);

const filter1 = ref('');
const filter4 = ref('');
const filter6 = ref();

const visible = ref(false);

</script>

<style>

</style>