<template>
  <footer class="footer-section">

    <div class="container footer-help-container">
      <div class="footer-help-box">
        <label>We're here to help</label>
        <h2>080 2395903</h2>
        <p>Monday - Friday 9AM - 5PM EST Time Zone</p>
      </div>
    </div>

    <div class="container">
      <div class="footer-content pt-5 pb-5">
        <div class="grid m-0">

          <!-- <div class="col-12 md:col-12 lg:col-12 mb-50">
            <div class="footer-widget">
              <div class="footer-logo">
                <a>
                  <img class="site-logo-spaceDesign" src="../../assets/images/logo/logo.png">
                </a>
              </div>
            </div>
          </div> -->

          <div class="col-12 md:col-4 lg:col-4 mb-50">
            <div class="footer-widget">
              <div class="footer-text">
                <div class="footer-widget">
                  <div class="footer-logo">
                    <a>
                      <img class="site-logo-spaceDesign" src="../../assets/images/logo/logo.png">
                    </a>
                  </div>
                </div>

                <p>
                  MECompare Insurance is Emirate's pioneering digital insurance agency, redefining insurance by
                  combining innovation, technology, and personalized service. Founded in Saudi Arabia, we are committed
                  to making insurance hassle-free and accessible for everyone.
                </p>
                <div class="licened-img">
                  <img class="" src="../../assets/images/Icons/licened.png">
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 md:col-2 lg:col-2 mb-30 footer-list-item-pt">
            <div class="footer-widget">
              <div class="footer-widget-heading">
                <h3>Products</h3>
              </div>
              <ul>
                <li>
                  <router-link to="/">
                    Motor insurance
                  </router-link>
                </li>
                <li>
                  <router-link to="/">
                    Health insurance
                  </router-link>
                </li>
                <li>
                  <router-link to="/">
                    Travel insurance
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-12 md:col-2 lg:col-2 mb-30 footer-list-item-pt">
            <div class="footer-widget">
              <div class="footer-widget-heading">
                <h3>Company</h3>
              </div>
              <ul>
                <li>
                  <router-link to="/">
                    About us
                  </router-link>
                </li>
                <li>
                  <router-link to="/">
                    Legal
                  </router-link>
                </li>
                <li>
                  <router-link to="/">
                    Privacy Policy
                  </router-link>
                </li>
                <li>
                  <router-link to="/">
                    Terms & Conditions
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-12 md:col-2 lg:col-2 mb-30 footer-list-item-pt">
            <div class="footer-widget">
              <div class="footer-widget-heading">
                <h3>Claim</h3>
              </div>
              <ul>
                <li>
                  <router-link to="/">
                    File a claim
                  </router-link>
                </li>
                <li>
                  <router-link to="/">
                    Track a claim
                  </router-link>
                </li>
                <li>
                  <router-link to="/">
                    Claim status
                  </router-link>
                </li>
                <li>
                  <router-link to="/">
                    Claim assistance
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-12 md:col-2 lg:col-2 mb-30">
            <div class="footer-widget social-widget-footer">
              <div class="footer-social-icon">
                <a href="#">
                  <img class="" src="../../assets/images/Icons/facebook-app-symbol.svg">
                </a>
                <a href="#">
                  <img class="" src="../../assets/images/Icons/instagram.svg">
                </a>
                <a href="#">
                  <img class="" src="../../assets/images/Icons/twitter.svg">
                </a>
                <a href="#">
                  <img class="" src="../../assets/images/Icons/youtube.svg">
                </a>
              </div>
            </div>

            <ul class="footer-support-dropdown">
              <li class="header-left-menuItems header-left-menuItemsServices menuItemsCompany">
                <p> Support <i class="pi pi-angle-down" style="font-size: 1rem; padding-left: 0.1rem;"></i>
                </p>

                <div class="mega-box">
                  <div class="content">
                    <div class="grid w-full m-0">
                      <div class="col-12 md:col-12 lg:col-12">
                        <router-link to="/">
                          <div class="mega-menu-list">
                            <div class="mega-menu-list-info">
                              <h4 class="mega-menu-title">
                                <i class="pi pi-phone" style="font-size: 1rem"></i>
                                080 2395903
                              </h4>
                            </div>
                          </div>
                        </router-link>
                      </div>

                      <div class="col-12 md:col-12 lg:col-12 whatsapp">
                        <router-link to="/">
                          <div class="mega-menu-list border-0">
                            <div class="mega-menu-list-info">
                              <h4 class="mega-menu-title">
                                <i class="pi pi-whatsapp" style="font-size: 1rem"></i>
                                Chat Now
                              </h4>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div class="col-12 md:col-12 lg:col-12 mb-50 mt-5">
            <div class="footer-payment-cards-img">
              <img class="" src="../../assets/images/Icons/cards-image-all.png">
            </div>
            <p class="copyright-text">© 2024 MECompare Insurance. All rights reserved.</p>
          </div>

        </div>
      </div>
    </div>
  </footer>
</template>

<style>
.footer-section {
  min-height: 450px;
  position: relative;
  padding: 0px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #F9F9F9;
  border-radius: 80px 80px 0 0;
}

.footer-help-box {
  background: #FFAC00;
  min-height: 170px;
  width: 450px;
  border-radius: 45px;
  margin: auto;
  margin-top: -120px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  row-gap: 0.1rem;
}

ul {
  margin: 0px;
  padding: 0px;
}

.footer-cta {
  border-bottom: 1px solid #373636;
}

.single-cta i {
  color: #fff;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}

.cta-text {
  padding-left: 15px;
  display: inline-block;
}

.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.cta-text span {
  color: #fff;
  font-size: 15px;
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo img {
  max-width: 150px;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 16px;
  color: #000;
  line-height: 28px;
}

.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}

.footer-social-icon {
  margin-top: 0.6rem;
}

.footer-social-icon a {
  color: #000;
  font-size: 16px;
  margin-right: 15px;
  background: #fff !important;
  width: 32px;
  height: 32px;
  display: inline-flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}

.facebook-bg {
  background: #3B5998;
}

.twitter-bg {
  background: #55ACEE;
}

.google-bg {
  background: #DD4B39;
}

.footer-widget-heading h3 {
  color: #000;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 14px;
  position: relative;
}

.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #000;
  display: none;
}

.footer-widget ul li {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
  font-size: 16px;
}

.footer-widget ul li a:hover {
  color: #ffac00;
}

.footer-widget ul li a {
  color: #000;
  text-transform: capitalize;
  background: transparent;
}

.subscribe-form {
  position: relative;
  overflow: hidden;
}

.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2E2E2E;
  border: 1px solid #2E2E2E;
  color: #fff;
}

.subscribe-form button {
  position: absolute;
  right: 0;
  background: #fff;
  padding: 13px 20px;
  border: 1px solid #fff;
  top: 0;
}

.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}

.copyright-area {
  background: #202020;
  padding: 25px 0;
}

.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}

.copyright-text p a {
  color: #fff;
}

.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}

.footer-menu li:hover a {
  color: #fff;
}

.footer-menu li a {
  font-size: 14px;
  color: #878787;
}

.app-download-icon-footer a img.site-logo-amaken {
  width: 160px;
}

.app-download-icon-footer {
  display: flex;
  align-items: center;
}

.footer-text {
  padding-right: 6rem;
}
</style>