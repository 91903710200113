<template>
    <HeaderJourney />

    <div class="main-upload-document-page">
        <div class="container">
            <div class="grid ml-0 mr-0 box-width-md">
                <div class="col-12 md:col-12 lg:col-12">
                    <div class="progress-bar-container">
                        <div class="progress-head">
                            <p class="progress-bar-label back-btn-flow" @click="back_to_quotations_listing_page">
                                <i class="pi pi-arrow-left"></i>
                                Back
                            </p>
                        </div>
                        <!-- <progressBar-prime :value="20"></progressBar-prime> -->
                    </div>
                </div>
            </div>

            <div class="grid ml-0 mr-0 box-width-md">
                <div class="col-12 md:col-12 lg:col-12">
                    <h2 class="upload-document-title mb-5">Upload the required documents</h2>

                    <div class="uploaded-document-info">
                        <div class="uploaded-document-info-box" v-for="item in document_lists" :key="item">
                            <div class="document-dummy-img">
                                <img src="../../assets/images/document-dummy.png">
                            </div>
                            <h4>{{ item.name }}</h4>
                            <p>{{ item.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <template v-for="(document, document_index) in document_lists" :key="document_index">
                <div class="grid ml-0 mr-0 box-width-md"
                    v-for="(document_detail, document_detail_index) in document.document_detail_list"
                    :key="document_detail_index">
                    <div class="col-12 md:col-12 lg:col-12">
                        <h2 class="upload-document-title mb-1" v-if="document_detail_index==0">{{ document.name }}</h2>
                        <p class="upload-document-para">{{ document_detail.name }}</p>

                        <div class="uploaded-document-area">
                            <div class="upoad-doc-box">
                                <img src="../../assets/images/Icons/upload-doc-icon.svg">
                                <p class="m-0">{{ document_detail.allowed_file_types }}</p>
                                <p class="m-0">Maximum Size: 2MB</p>

                                <div class="fileUpload">
                                    <input type="file" class="upload" :name="document_detail.id"
                                        @change="handleFileUpload($event, document, document_index, document_detail, document_detail_index)" />
                                    <span>Browse</span>
                                </div>

                            </div>

                            <div class="upload-doc-progress-area">
                                <div class="progressbar-top-area">
                                    <div class="progressbar-box-icon">
                                        <img src="../../assets/images/Icons/progress-box-icon.svg">
                                    </div>
                                    <div class="progressbar-info-area">
                                        <p>{{ document_detail.visible_file_name }}</p>
                                        <div>
                                            <span>{{ document_detail.allowed_file_types }}</span>
                                            <span>{{document_detail.total_size_percent}}%</span>
                                        </div>
                                    </div>
                                </div>
                                <progressBar-prime :value="document_detail.total_size_percent"></progressBar-prime>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div class="grid ml-0 mr-0 box-width-md">
                <button-prime class="flow-continuebtn" label="Submit" :loading="loading" @click="validate_documents" />
            </div>
        </div>
    </div>
    <toast-prime />
</template>

<script>
import HeaderJourney from '../Header/HeaderJourney.vue'
import axios from 'axios'
export default {
    data() {
        return {
            document_lists : [],
            loading : false,
        }
    },  
    mounted() {
        this.upload_document_lists();
    },
    components: {
        HeaderJourney
    },
    methods : {
        back_to_quotations_listing_page : function() {
            this.$router.push({
                path : '/motor/plan-listing/'+this.$route.params.quotation_id,
            });
        },
        upload_document_lists : function () {
            axios.get(this.api_url+'motor/upload_document_lists', {
                params : {
                    quotation_id : this.$route.params.quotation_id
                }
            }).then((response) => {
                this.document_lists = response.data.data;
            });
        },
        handleFileUpload(event, document, document_index, document_detail, document_detail_index){
            this.document_lists[document_index].document_detail_list[document_detail_index].total_size_percent = 0;
            this.document_lists[document_index].document_detail_list[document_detail_index].document_uploaded = false;
            let formData = new FormData();
            formData.append('file', event.target.files[0]);
            formData.append('id', document_detail.id);
            formData.append('quotation_id', this.$route.params.quotation_id);
            axios.post(this.api_url+'motor/upload_quotation_document',
                formData,
            ).then((response) => {
                this.document_lists[document_index].document_detail_list[document_detail_index].total_size_percent = 100;
                this.document_lists[document_index].document_detail_list[document_detail_index].document_uploaded = true;
                this.document_lists[document_index].document_detail_list[document_detail_index].visible_file_name = response.data.data.file_name;
            }).catch((error) => {
                if(error.response.status == 401) {
                    this.$toast.add({ severity: 'error', detail: error.response.data.errors, life: 3000 });
                }
            });
        },  
        validate_documents : function () {
            this.loading = true;
            for (let i = 0; i < this.document_lists.length; i++) {
                for (let j = 0; j < this.document_lists[i].document_detail_list.length; j++) {
                    if (this.document_lists[i].document_detail_list[j].document_uploaded == false) {
                        this.$toast.add({ severity: 'error', detail: 'Please upload all the documents!', life: 3000 });
                        this.loading = false;
                        return false;
                    }
                }
            }
            axios.get(this.api_url+'motor/update_quotation_details',{
                params : {
                    quotation_id : this.$route.params.quotation_id,
                    status : 'pending-review',
                }
            }).then((response) => {
                if (response.data.data == true) {
                    axios.get(this.api_url+'motor/send_email_and_sms_on_request_complete',{
                        params : {
                            quotation_id : this.$route.params.quotation_id
                        }
                    }).then((response) => {
                        if (response.status == 200){
                            this.$router.push({
                                path : '/motor/purchase-completed/'+this.$route.params.quotation_id,
                            });
                        }
                    });
                    
                }
            });
            
        }
    }
}
</script>


<style>

</style>