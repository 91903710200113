<template>
    <HeaderView />

    <div class="terms-conditon-page">
        <div class="container">
            <div class="grid ml-0 mr-0">
                <div class="col-12 md:col-12 lg:col-12">
                    <h2 class="terms-and-condition-title">Privacy Policy</h2>

                    <p class="para-terms-and-condition">
                        Welcome to MeCompare Insurance! At MeCompare, we are committed to safeguarding your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you interact with our services.
                    </p>

                    <h3 class="small-heading">
                        1. Information We Collect:
                    </h3>

                    <p class="para-terms-and-condition">
                        We may collect personal information such as your name, contact details, date of birth, identification documents, and other relevant details when you use our services or visit our website. Additionally, we may collect non-personal information, such as device information and website usage data.
                    </p>

                    <h3 class="small-heading">
                        2. How We Use Your Information:
                    </h3>

                    <p class="para-terms-and-condition">
                        We use the information collected to provide, maintain, and improve our insurance services. This includes processing your insurance applications, managing claims, and offering personalized recommendations. We may also use your information for communication purposes, such as updates, promotions, and policy-related notifications
                    </p>

                    <h3 class="small-heading">
                        3. Information Sharing:
                    </h3>

                    <p class="para-terms-and-condition">
                        Your privacy is important to us, and we do not sell, trade, or otherwise transfer your personal information to third parties without your consent. We may share information with our trusted partners, service providers, or regulatory authorities when necessary to fulfill our contractual obligations or legal requirements.
                    </p>

                    <h3 class="small-heading">
                        4. Data Security:
                    </h3>

                    <p class="para-terms-and-condition">
                        We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. Our security protocols include encryption, firewalls, and regular security audits.
                    </p>

                    <h3 class="small-heading">
                        5. Cookies and Tracking:
                    </h3>

                    <p class="para-terms-and-condition">
                        We use cookies and similar technologies to enhance your online experience. These technologies help us analyze website traffic, personalize content, and improve our services. You can manage your cookie preferences through your browser settings.
                    </p>

                    <h3 class="small-heading">
                        6. Your Rights:
                    </h3>

                    <p class="para-terms-and-condition">
                        You have the right to access, correct, or delete your personal information. If you have any inquiries or requests regarding your data, please contact our Data Protection Officer at [contact@email.com].
                    </p>

                    <h3 class="small-heading">
                        7. Updates to Privacy Policy:
                    </h3>

                    <p class="para-terms-and-condition">
                        We may update this Privacy Policy to reflect changes in our practices or legal requirements. We encourage you to review this policy periodically for any updates.

                        <br><br>

                        By using our services, you consent to the terms outlined in this Privacy Policy. If you do not agree with these terms, please refrain from using our services.

                        <br><br>

                        Thank you for choosing MeCompare Insurance. Your privacy and trust are of utmost importance to us.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <FooterView />
</template>

<script>
import HeaderView from '../Header/HeaderView.vue'
import FooterView from '../Footer/FooterView.vue'

export default {
    components: {
        HeaderView,
        FooterView,
    },
    data() {
        return {

        }

    },
    methods: {

    }
}
</script>