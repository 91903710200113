<template>
    <HeaderView />

    <div class="terms-conditon-page">
        <div class="container">
            <div class="grid ml-0 mr-0">
                <div class="col-12 md:col-12 lg:col-12">
                    <h2 class="terms-and-condition-title">Terms & Conditions</h2>

                    <p class="para-terms-and-condition">
                        Welcome to MECompare! By accessing or using our services, you agree to comply with the
                        following terms and conditions. Please read this agreement carefully before proceeding.
                    </p>

                    <h3 class="small-heading">
                        1. Acceptance of Terms:
                    </h3>

                    <p class="para-terms-and-condition">
                        By using MECompare's services, you agree to be bound by these terms and conditions. If you
                        do not agree with any part of this agreement, you may not use our services.
                    </p>

                    <h3 class="small-heading">
                        2. Eligibility:
                    </h3>

                    <p class="para-terms-and-condition">
                        You must be of legal age to enter into a binding contract to use our services. By using Mango
                        Insurance, you represent and warrant that you meet the eligibility requirements.
                    </p>

                    <h3 class="small-heading">
                        3. Service Description:
                    </h3>

                    <p class="para-terms-and-condition">
                        MeCompare Insurance provides insurance-related services, including but not limited to motor
                        insurance, medical insurance, and travel insurance. The terms and conditions specific to each
                        service are outlined in relevant sections.
                    </p>

                    <h3 class="small-heading">
                        4. User Responsibilities:
                    </h3>

                    <p class="para-terms-and-condition">
                        You agree to provide accurate, complete, and up-to-date information when using our services. It
                        is your responsibility to maintain the confidentiality of your account information and password.
                    </p>

                    <h3 class="small-heading">
                        5. Privacy Policy:
                    </h3>

                    <p class="para-terms-and-condition">
                        Your use of MeCompare Insurance's services is also governed by our Privacy Policy. By using our
                        services, you consent to the collection, use, and sharing of your information as described in
                        the Privacy Policy.
                    </p>

                    <h3 class="small-heading">
                        6. Intellectual Property:
                    </h3>

                    <p class="para-terms-and-condition">
                        All content and materials provided by MECompare, including logos, text, graphics, and
                        software, are the property of MECompare and protected by intellectual property laws.
                    </p>

                    <h3 class="small-heading">
                        7. Third-Party Links:
                    </h3>

                    <p class="para-terms-and-condition">
                        Our services may contain links to third-party websites or services. MeCompare Insurance has no
                        control over such third-party sites and is not responsible for their content or practices.
                    </p>

                    <h3 class="small-heading">
                        8. Modifications:
                    </h3>

                    <p class="para-terms-and-condition">
                        MeCompare Insurance reserves the right to modify or terminate its services, terms, and
                        conditions at
                        any time without prior notice. Continued use of our services after modifications constitutes
                        acceptance of the updated terms.
                    </p>

                    <h3 class="small-heading">
                        9. Disclaimer of Warranties:
                    </h3>

                    <p class="para-terms-and-condition">
                        MeCompare Insurance's services are provided "as is" without any warranties. We do not guarantee
                        the
                        accuracy, completeness, or reliability of our services.
                    </p>

                    <h3 class="small-heading">
                        10. Limitation of Liability:
                    </h3>

                    <p class="para-terms-and-condition">
                        MeCompare Insurance shall not be liable for any direct, indirect, incidental, special, or
                        consequential damages arising out of or in any way connected with the use of our services.
                    </p>

                    <h3 class="small-heading">
                        11. Governing Law:
                    </h3>

                    <p class="para-terms-and-condition">
                        These terms and conditions are governed by the laws of [Country]. Any disputes arising out of or
                        related to these terms shall be resolved through arbitration in accordance with the rules of
                        [Arbitration Institution].
                    </p>

                    <h3 class="small-heading">
                        12. Contact Information:
                    </h3>

                    <p class="para-terms-and-condition">
                        For inquiries regarding these terms and conditions, please contact us at [contact@email.com].

                        <br><br>

                        By using MECompare, you acknowledge that you have read, understood, and agree to these
                        terms and conditions.

                        <br><br>

                        Thank you for choosing MECompare.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <FooterView />
</template>

<script>
import HeaderView from '../Header/HeaderView.vue'
import FooterView from '../Footer/FooterView.vue'

export default {
    components: {
        HeaderView,
        FooterView,
    },
    data() {
        return {

        }

    },
    methods: {

    }
}
</script>